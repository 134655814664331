import React, { Component } from "react";
import moment from "../../../lib/moment.js";
import { LoadingScreen } from "../../../lib/theme.js";
import { Redirect } from "react-router-dom";
import withUpdatedOrders from "./shared/withUpdatedOrders";
import withBookingProcessController from "./shared/withBookingProcessController";
import {
  translationManager,
  translatorForNamespace
} from "../../../lib/TranslationManager";

const TIMEOUT_SECS = 3 * 60; // 3min

const t = translatorForNamespace("BookingInProgressPage");

// Displays different status text depending on situation
// TODO: Add timeout to order error page?
class BookingInProgressPage extends Component {
  constructor(...args) {
    super(...args);
    this.state = {
      timeout: false,
      expiryTime: null
    };
  }

  componentDidMount() {
    const expiryTime = moment().add(TIMEOUT_SECS, "seconds");
    this.setState({ expiryTime, timeout: false });
    setTimeout(() => {
      if (!this || !this.state) {
        return;
      }

      const { timeout, expiryTime: expTime } = this.state;

      if (
        !timeout &&
        !!expTime &&
        moment(expTime).valueOf() < moment().valueOf()
      ) {
        this.setState({ expiryTime: null, timeout: true });
      }
    }, expiryTime.valueOf() - moment().valueOf());
  }

  componentWillUnmount() {
    this.setState({ expiryTime: null, timeout: false });
  }

  render() {
    const {
      appState: {
        userReadable: { ordersById = {} }
      },
      match: {
        params: { orderId = null }
      }
      // history,
    } = this.props;
    const { timeout } = this.state;

    console.log(`Showing ${window.location.href}`);

    if (timeout) {
      return <Redirect to={`/book/booking-error/${orderId}/timeout`} />;
    }

    const order = ordersById[orderId];

    // console.log("Order = ", order);

    let goNext = false;
    const text = t("Booking in progress...");
    if (order) {
      if (order.status === "error") {
        return <Redirect to={`/book/booking-error/${orderId}/`} />;
      }

      if (order.statusDetails) {
        goNext =
          order.statusDetails.booked ||
          (!!order.statusDetails.paid && !order.statusDetails.canBeBooked) ||
          (!!order.statusDetails.paid &&
            order.statusDetails.needsManualBooking);
      }
    }

    if (goNext) {
      return <Redirect to="/book/booking-confirmed/" />;
    }

    return <LoadingScreen text={text} />;
  }
}

export default withBookingProcessController(
  withUpdatedOrders(BookingInProgressPage)
);
