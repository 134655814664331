import { Component } from "react";
import firebase from "firebase";
import filterProps from "filter-properties";
import shallowObjectEquals from "object-equal";

export default class UserDataListener extends Component {
  constructor(...args) {
    super(...args);
    this.currentUserPath = null;

    // Bindings
    this.onDataUpdate = this.onDataUpdate.bind(this);
    this.onReadableDataUpdate = this.onReadableDataUpdate.bind(this);
  }

  componentDidMount() {
    this.startListening();
  }

  componentDidUpdate(prevProps) {
    const whitelist = ["loggedIn", "userId", "online", "userEmail"];

    if (
      !shallowObjectEquals(
        filterProps(whitelist, this.props),
        filterProps(whitelist, prevProps)
      )
    ) {
      this.stopListening();
      this.startListening();
    }
  }

  componentWillUnmount() {
    this.stopListening();
  }

  onDataUpdate(snapshot) {
    const { loggedIn, onUserDataUpdate = () => {} } = this.props;

    if (!loggedIn) {
      return;
    }

    const val = snapshot.val();
    onUserDataUpdate(val || {});
  }

  onReadableDataUpdate(snapshot) {
    const { loggedIn, onUserReadableDataUpdate = () => {} } = this.props;

    if (!loggedIn) {
      return;
    }

    const val = snapshot.val();
    onUserReadableDataUpdate(val || {});
  }

  startListening() {
    const { loggedIn, online, userId } = this.props;
    if (!loggedIn || !userId || !online) {
      return;
    }

    if (!this.currentUserPath) {
      this.currentUserPath = `/users/${userId}/`;
      firebase
        .database()
        .ref(this.currentUserPath)
        .on("value", this.onDataUpdate, () => {
          /* Do nothing on permission error */
        });
    }

    if (!this.currentUserReadablePath) {
      this.currentUserReadablePath = `/users-readable/${userId}/`;
      firebase
        .database()
        .ref(this.currentUserReadablePath)
        .on("value", this.onReadableDataUpdate, () => {
          /* Do nothing on permission error */
        });
    }
  }

  stopListening() {
    if (this.currentUserPath) {
      firebase
        .database()
        .ref(this.currentUserPath)
        .off("value", this.onDataUpdate);
      this.currentUserPath = null;
    }

    if (this.currentUserReadablePath) {
      firebase
        .database()
        .ref(this.currentUserReadablePath)
        .off("value", this.onReadableDataUpdate);
      this.currentUserReadablePath = null;
    }
  }

  render() {
    return null;
  }
}
