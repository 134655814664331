import React from "react";
import { BillingDetailsPage as BillingDetailsPageUI } from "../../../lib/theme";
import withErrorManagementOnTripsDeletion from "./shared/withErrorManagementOnTripsDeletion";
import withBookingProcessController from "./shared/withBookingProcessController";

const BillingDetailsPage = (props) => {
  const {
    sdk,
    goBack = () => {},
    goToLastAllowedStep = () => {},
    history,
  } = props;

  return (
    <BillingDetailsPageUI
      sdk={sdk}
      userPassengerId={sdk.passengers.getUserPassengerId()}
      savedPassengers={sdk.passengers.getAllSavedPassengers()}
      billingPassengerId={sdk.billingDetails.getBillingPassengerId()}
      setBillingInfo={async (data) => {
        sdk.billingDetails.setBillingDetails(data);
        goToLastAllowedStep();
      }}
      translatorForNamespace={sdk.i18n.translatorForNamespace}
      onBack={goBack}
      history={history}
    />
  );
};
export default withBookingProcessController(
  withErrorManagementOnTripsDeletion(BillingDetailsPage)
);
