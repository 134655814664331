import BillingDetailsSdk from "./BillingDetailsSdk";
import BookingFormSdk from "./BookingFormSdk";
import BookingProcessSdk from "./BookingProcessSdk";
import Covid19Sdk from "./Covid19Sdk";
import PassengersSdk from "./PassengersSdk";
import SeatsSdk from "./SeatsSdk";
import RoutesSdk from "./RoutesSdk";
import TerminalsSdk from "./TerminalsSdk";
import TypesSdk from "./TypesSdk";
import UserSettingsSdk from "./UserSettingsSdk";
import {
  translationManager,
  translatorForNamespace,
} from "../TranslationManager";

export default class BuseaFrontSdk {
  constructor({
    setSession,
    setUserState,
    setAnonymousUserReadableIfAppropriate,
    logOut,
    getAppState,
  }) {
    Object.assign(this, {
      setSession,
      setUserState,
      setAnonymousUserReadableIfAppropriate,
      logOut,
      getAppState,
    });

    this.billingDetails = new BillingDetailsSdk(this);
    this.bookingForm = new BookingFormSdk(this);
    this.bookingProcess = new BookingProcessSdk(this);
    this.covid19 = new Covid19Sdk(this);
    this.passengers = new PassengersSdk(this);
    this.routes = new RoutesSdk(this);
    this.seats = new SeatsSdk(this);
    this.terminals = new TerminalsSdk(this);
    this.types = new TypesSdk(this);
    this.userSettings = new UserSettingsSdk(this);
    this.i18n = {
      translationManager,
      translatorForNamespace,
    };
  }
}
