import React from "react";
import { EditPassengersPage as EditPassengersPageUI } from "../../../lib/theme";
import withSettingsWidgetProps from "./shared/withSettingsWidgetProps";

const PassengersDetailsPage = (props) => {
  const {
    sdk,
    appState: {
      user: { passengerId },
    },
    history,
  } = props;

  return (
    <EditPassengersPageUI
      {...props}
      userPassengerId={passengerId}
      savedPassengers={sdk.passengers.getAllSavedPassengers()}
      onPassengerDelete={async (p) => sdk.passengers.deletePassenger(p.id)}
      onPassengerEdit={(p) => {
        history.push(`/settings/passengers/${p.id}/edit/`);
      }}
      addPassengerLink="/settings/passengers/add"
      onBack={() => history.push("/settings/")}
    />
  );
};
export default withSettingsWidgetProps(PassengersDetailsPage);
