import arrayFlatten from 'array-flatten';

export function findTickets(ordersById, { orderId = null, ticketId = null }) {
  let tickets = [];

  // If orderId is defined, then filter by order
  if (orderId) {
    if (ordersById[orderId] && ordersById[orderId].statusDetails.paid === true) {
      const o = ordersById[orderId];
      tickets = [o.outboundTrip, o.returnTrip].filter(t => !!t);
    }

    // Else get all available tickets
  } else {
    tickets = arrayFlatten(
      Object.values(ordersById)
        .filter(o => o.statusDetails.paid === true)
        .map(o => [o.outboundTrip, o.returnTrip]),
    ).filter(t => !!t);
  }

  // If ticketId is defined, filter by ticketId
  if (ticketId) {
    tickets = tickets.filter(t => t.id === ticketId);
  }

  // Return
  return tickets;
}

export function findOneTicket(ordersById, { orderId = null, ticketId = null }) {
  const matches = findTickets(ordersById, { orderId, ticketId });

  if (matches.length === 0) {
    return null;
  }
  return matches[0];
}
