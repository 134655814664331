import React, { PureComponent } from "react";
import { Redirect } from "react-router-dom";

const withBookingProcessController = (WrappedComponent) =>
  class WithBookingProcessController extends PureComponent {
    render() {
      const { sdk, history } = this.props;

      // Redirect to another step in the flow
      // if the current page can't be displayed
      if (!sdk.bookingProcess.processStepCanDisplay()) {
        console.log("Returning to max() = " + sdk.bookingProcess.max());
        return <Redirect to={sdk.bookingProcess.max()} />;
      }

      return (
        <WrappedComponent
          {...this.props}
          goBack={() => sdk.bookingProcess.goBack(history)}
          goForward={() => sdk.bookingProcess.goForward(history)}
          goToLastAllowedStep={() =>
            sdk.bookingProcess.goToLastAllowedStep(history)
          }
          prevStep={sdk.bookingProcess.prev()}
          nextStep={sdk.bookingProcess.next()}
          maxAllowedStep={sdk.bookingProcess.max()}
        />
      );
    }
  };

export default withBookingProcessController;
