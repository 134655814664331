import React from "react";
import { findOneTicket } from "../lib/ordersUtils";
import { TicketNotFoundPage as TicketNotFoundPageUI } from "../../../../lib/theme";
import { translatorForNamespace } from "../../../../lib/TranslationManager";

const withTicketFromUrl = (WrappedComponent) => (props) => {
  const {
    appState: {
      userReadable: { ordersById = {} },
    },
    match: {
      params: { ticketId },
    },
    history,
  } = props;

  const ticket = findOneTicket(ordersById, { ticketId });
  console.log("ticket = ", ticket);
  if (!ticket) {
    return (
      <TicketNotFoundPageUI
        translatorForNamespace={translatorForNamespace}
        onBack={() => {
          history.goBack();
        }}
      />
    );
  }

  return (
    <WrappedComponent
      ticket={ticket}
      onBack={() => {
        history.goBack();
      }}
      {...props}
    />
  );
};

export default withTicketFromUrl;
