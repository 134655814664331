import React from "react";
import GenericTripDetailsPage from "./shared/GenericTripDetailsPage";

const OutTripDetailsPage = (props) => {
  const {
    appState: {
      session: { outboundTrip },
    },
    history,
    setSession = () => {},
  } = props;

  return (
    <GenericTripDetailsPage
      {...props}
      selectedTrip={outboundTrip}
      isReturn={false}
      showSeatsLink="/book/out/select/seats/"
      showStopsLink="/book/out/select/stops/"
      confirmLink="/book/return/"
      onBack={history.goBack}
      setTrip={async (trip) => {
        await setSession({ outboundTrip: trip });
      }}
    />
  );
};

export default OutTripDetailsPage;
