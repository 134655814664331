import React, { Component } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { LastLocationProvider } from "react-router-last-location";
import check from "check-types";
import firebase from "firebase";
import moment from "../../lib/moment.js";
import loadImage from "image-promise";
import arrayUnique from "array-unique";
import uniqid from "uniqid";
import { format as formatEmail } from "@seafront/types/dist/email";
import { firebasePost } from "@seafront/firebase-api-client";
import { Router, getImagesToPreload } from "../../lib/theme.js";
import { translationManager } from "../../lib/TranslationManager";
import isNative from "../../lib/is-native";
import BuseaFrontSdk from "../../lib/sdk";

import AuthListener from "./listeners/AuthListener";
import OnlineListener from "./listeners/OnlineListener";
import RidesListener from "./listeners/RidesListener";
import RoutesAndLocationsListener from "./listeners/RoutesAndLocationsListener";
import UserDataListener from "./listeners/UserDataListener";
import ExpandedRideListener from "./listeners/ExpandedRideListener";

import BetaOnboarding from "./BetaOnboarding";
import RouteBroadcaster from "./RouteBroadcaster";

import WelcomePage from "./pages/WelcomePage";
import LoginPage from "./pages/LoginPage";
import LoginByPhonePage from "./pages/LoginByPhonePage";
import ForgotPasswordPage from "./pages/ForgotPasswordPage";
import SettingsPage from "./pages/SettingsPage";
import SignUpPage from "./pages/SignUpPage";
import SignUpOkPage from "./pages/SignUpOkPage";
import OrdersPage from "./pages/OrdersPage";
import LogoutPage from "./pages/LogoutPage";
import TicketDetailsPage from "./pages/TicketDetailsPage";
import TicketStopsPage from "./pages/TicketStopsPage";
import TicketBoardingPassPage from "./pages/TicketBoardingPassPage";
import BookingFormPage from "./pages/BookingFormPage";
import OutTripsListPage from "./pages/OutTripsListPage";
import ReturnTripsListPage from "./pages/ReturnTripsListPage";
import OutTripDetailsPage from "./pages/OutTripDetailsPage";
import OutTripSeatSelectionPage from "./pages/OutTripSeatSelectionPage";
import OutTripStopsPage from "./pages/OutTripStopsPage";
import ReturnTripDetailsPage from "./pages/ReturnTripDetailsPage";
import ReturnTripSeatSelectionPage from "./pages/ReturnTripSeatSelectionPage";
import ReturnTripStopsPage from "./pages/ReturnTripStopsPage";
import PassengerDetailsPage from "./pages/PassengerDetailsPage";
import PassengerDetailsAddPage from "./pages/PassengerDetailsAddPage";
import BillingDetailsPage from "./pages/BillingDetailsPage";
import CheckoutPage from "./pages/CheckoutPage";
import CovidInfoPage from "./pages/CovidInfoPage";
import LegacyCreditCardPaymentPage from "./pages/CreditCardPaymentPage";
import StripeCheckoutPage from "./pages/StripeCheckoutPage";
import BookingConfirmedPage from "./pages/BookingConfirmedPage";
import BookingInProgressPage from "./pages/BookingInProgressPage";
import BookingErrorPage from "./pages/BookingErrorPage";
import AddPassengerPopup from "./pages/AddPassengerPopup";
import EditPassengerPopup from "./pages/EditPassengerPopup";
import EditPassengersPage from "./pages/EditPassengersPage";
import DepartureDatePopup from "./pages/DepartureDatePopup";
import ReturnDatePopup from "./pages/ReturnDatePopup";
import LanguageSettingsPage from "./pages/LanguageSettingsPage";

import translations from "../../lang/translations.json";
import { functionsEndpoint } from "../../config/firebase.json";
import {
  hasOnboarding,
  hasWelcomePage,
  supportedLanguages
} from "../../config/app.json";

const BETA_ONBOARDING_DISPLAYED = false;

// Use Stripe Checkout on Web only for the moment
// Will need to be enabled on mobile later
/* const CreditCardPaymentPage = isNative()
  ? LegacyCreditCardPaymentPage
  : StripeCheckoutPage;
*/
// DISABLED TEMPORARILY
const CreditCardPaymentPage = LegacyCreditCardPaymentPage;

// Now implemented in "../../lib/theme";
// const Router = isNative() ? HashRouter : BrowserRouter;

export default class Busea extends Component {
  static defaultUserReadableState() {
    return {
      isAuthorizedTester: false,
      ordersById: {},
      cards: {},
      emailVerified: false
    };
  }

  static defaultLocalState() {
    return {
      loggedIn: false,
      uid: null
    };
  }

  static defaultUserState() {
    return {
      lang: translationManager.getCurrentLanguage(),
      email: null,
      otherEmails: [],
      savedPassengers: {},
      passengerId: null,
      displayName: null,
      emailVerified: false,
      photoURL: null,
      providerId: null,
      uid: null
    };
  }

  static defaultSessionState() {
    return {
      sessionId: uniqid(),
      isSigningIn: false,
      hasSeenWelcomeScreen: false,
      selectedDeparture: null,
      selectedArrival: null,
      numberOfPassengers: 1,
      outDate: moment().format("YYYY-MM-DD"),
      returnDate: null,
      disableReturnField: false,
      passengerDetails: null,
      billingDetailsId: null,
      outboundTrip: null,
      returnTrip: null,
      orderId: null,
      lastOrderId: null,
      covidInfo: null,
      returnTripSeats: null,
      outboundTripSeats: null,
      outboundSeatsErrors: null,
      returnSeatsErrors: null
    };
  }

  static defaultState() {
    return {
      online: window.navigator.onLine,
      local: Busea.defaultLocalState(),
      user: Busea.defaultUserState(),
      userReadable: Busea.defaultUserReadableState(),

      session: Busea.defaultSessionState(),

      cache: {
        routes: {},
        locations: {},
        searches: {}
      }
    };
  }

  static getSavedState() {
    // Create initial state
    const state = Busea.defaultState();

    // Get locally saved state if any
    let parsedState = {};
    try {
      const savedState = localStorage.getItem("busea.store");

      if (check.nonEmptyString(savedState)) {
        parsedState = {
          ...JSON.parse(savedState),
          online: window.navigator.onLine
        };
      }
    } catch (err) {
      // Do nothing
    }

    // If a previous state was saved, apply it
    // TODO: Better checks for security
    if (check.nonEmptyObject(parsedState)) {
      state.local = Object.assign(state.local, parsedState.local || {});
      state.user = Object.assign(state.user, parsedState.user || {});
      state.userReadable = Object.assign(
        state.userReadable,
        parsedState.userReadable || {}
      );
      state.session = Object.assign(state.session, parsedState.session || {});
      state.cache = Object.assign(state.cache, parsedState.cache || {});
    }

    // Make sure we don't restore dates from the past
    const { outDate, returnDate } = state.session;
    let outDateMoment = outDate ? moment(outDate) : moment();
    let returnDateMoment = returnDate ? moment(returnDate) : null;

    if (outDateMoment && outDateMoment.valueOf() < Date.now()) {
      outDateMoment = moment();
    }
    if (returnDateMoment && returnDateMoment.valueOf() < Date.now()) {
      returnDateMoment = moment();
    }
    if (
      outDateMoment &&
      returnDateMoment &&
      outDateMoment.valueOf() > returnDateMoment.valueOf()
    ) {
      returnDateMoment = outDateMoment;
    }
    state.session = Object.assign(state.session, {
      outDate: outDateMoment.format("YYYY-MM-DD"),
      returnDate: returnDateMoment
        ? returnDateMoment.format("YYYY-MM-DD")
        : null
    });

    // Clear some stuff from the session
    /*
    state.session = Object.assign(state.session, {
      outboundTrip: null,
      returnTrip: null,
    });
    */

    // Return
    return state;
  }

  static saveState(state) {
    const stringifiedState = JSON.stringify(state);
    localStorage.setItem("busea.store", stringifiedState);

    // Save state at ReactNative level if we're running inside an App
    Busea.sendMessageToNativeWrapperIfAny("storage", {
      key: "busea.store",
      value: stringifiedState
    });
  }

  // If on ReactNative, send a message to wrapper window
  // Else, do nothing
  static sendMessageToNativeWrapperIfAny(messageType, data = {}) {
    if (
      isNative() &&
      window &&
      window.ReactNativeWebView &&
      window.ReactNativeWebView.postMessage
    ) {
      window.ReactNativeWebView.postMessage(
        JSON.stringify({ type: messageType, ...(data || {}) })
      );
    }
  }

  constructor(...args) {
    super(...args);

    // Init translation manager
    translationManager.init(
      "busea",
      firebase,
      translations,
      "/translations",
      supportedLanguages
    );
    this.state = Busea.getSavedState();

    // Bindings
    this.onAuthStateChange = this.onAuthStateChange.bind(this);
    this.onRoutesUpdate = this.onRoutesUpdate.bind(this);
    this.onLocationsUpdate = this.onLocationsUpdate.bind(this);
    this.onRidesUpdate = this.onRidesUpdate.bind(this);
    this.onExpandedRideDataUpdate = this.onExpandedRideDataUpdate.bind(this);
    this.onUserDataUpdate = this.onUserDataUpdate.bind(this);
    this.getAppState = this.getAppState.bind(this);
    this.getRidesData = this.getRidesData.bind(this);
    this.setSession = this.setSession.bind(this);
    this.setUserState = this.setUserState.bind(this);
    this.setLocalState = this.setLocalState.bind(this);
    this.setCacheState = this.setCacheState.bind(this);
    this.onUserReadableDataUpdate = this.onUserReadableDataUpdate.bind(this);
    this.logOut = this.logOut.bind(this);
    this.setAnonymousUserReadableIfAppropriate = this.setAnonymousUserReadableIfAppropriate.bind(
      this
    );
    this.onOnlineStatusChange = this.onOnlineStatusChange.bind(this);

    // SDK instanciation
    this.sdk = new BuseaFrontSdk({
      getAppState: this.getAppState,
      setSession: this.setSession,
      setUserState: this.setUserState,
      setAnonymousUserReadableIfAppropriate: this
        .setAnonymousUserReadableIfAppropriate,
      logOut: this.logOut
    });
  }

  // Once loaded, warn React native if we're inside an app
  componentDidMount() {
    Busea.sendMessageToNativeWrapperIfAny("loaded");

    // And preload app images

    console.log("### imagesToPreload: ", getImagesToPreload());
    if (check.nonEmptyArray(getImagesToPreload())) {
      loadImage(getImagesToPreload() || [])
        .then(() => console.log("All images preloaded OK"))
        .catch(console.error);
    }
  }

  async onLogin(uid, email) {
    // Set user as logged in
    await this.setLocalState({ loggedIn: true, uid });

    // Get remote user state
    const { user: local } = this.state;
    // const { remoteConsoleChannel } = this.props;
    const remoteSnap = await firebase
      .database()
      .ref(`/users/${uid}`)
      .once("value");

    // Restore session
    const remote = Object.assign(
      Busea.defaultUserState(),
      remoteSnap.val() || {}
    );

    console.log("Logging in. Local passengers: ", local.savedPassengers);
    console.log("Logging in. Remote passengers: ", remote.savedPassengers);

    const newUser = {
      lang: remote.lang || local.lang,
      email: formatEmail(email),
      otherEmails: arrayUnique(
        [
          local.email || null,
          remote.email || null,
          ...(remote.otherEmails || []),
          ...(local.otherEmails || [])
        ]
          .filter(e => !!e && formatEmail(e) !== formatEmail(email))
          .map(e => formatEmail(e))
      ),

      savedPassengers: {
        ...(remote.savedPassengers || {}),
        ...(local.savedPassengers || {})
      },
      passengerId: remote.passengerId || local.passengerId
      // FIXME: Update this from time to time
      // remoteConsoleSessions: Object.assign({}, remote.remoteConsoleSessions || {}, {
      //   [remoteConsoleChannel]: moment().toISOString(),
      // }),
    };

    // Save it locally and in Firebase
    await this.setUserState(newUser);

    // Ask server to assign all anonymous orders and all anonymously saved cards to user
    // This will refresh userReadable
    await this.forceUserReadableRefresh();

    // Get user-readable info
    const userReadableSnap = await firebase
      .database()
      .ref(`/users-readable/${uid}`)
      .once("value");
    await this.setState({
      userReadable: Object.assign(
        Busea.defaultUserReadableState(),
        userReadableSnap.val() || {}
      )
    });
  }

  async onLogout() {
    if (isNative()) {
      window.ReactNativeWebView.postMessage(
        JSON.stringify({ type: "log-out" })
      );
    }

    await this.setLocalState(Busea.defaultLocalState()); // Setting loggedIn to false
    await this.setUserState(Busea.defaultUserState());
    await this.setAnonymousUserReadableIfAppropriate(
      Busea.defaultUserReadableState()
    );
    await this.setSession(Busea.defaultSessionState());

    console.log("👋 Successfully logged out!");
  }

  async onAuthStateChange(newUser) {
    const {
      online,
      local: { loggedIn, uid }
    } = this.state;

    console.log("🔑 onAuthStateChanged: ", newUser);

    // Only log out if we're online
    if (!newUser || !newUser.uid) {
      if (online) {
        // If user was logged out, reset everything
        if (loggedIn || check.nonEmptyString(uid)) {
          console.log("🔑 Logging out...");
          await this.onLogout();
        }
      }
      // If it's a login, upload state to DB!
    } else if (!loggedIn || !check.nonEmptyString(uid)) {
      console.log("🔑 It's a log in!: ", newUser);
      await this.onLogin(newUser.uid, newUser.email);
    }

    // Remove sign in progress indicator
    await this.setSession({
      isSigningIn: false
    });
  }

  async onRoutesUpdate(newRoutes) {
    await this.setCacheState({ routes: newRoutes });
  }

  async onLocationsUpdate(newLocations) {
    await this.setCacheState({ locations: newLocations });
  }

  async onRidesUpdate(origin, destination, date, data) {
    const {
      cache: { rides = {}, expandedRidesData = {} }
    } = this.state;
    const path = `${origin}/${destination}/${date}`;

    // Merge expanded fields on new rides data if any
    let newData = data;
    if (check.array(data)) {
      newData = data
        .map(r => {
          if (check.nonEmptyObject(expandedRidesData[r.id])) {
            return { ...r, ...expandedRidesData[r.id] };
          }
          return r;
        })
        .filter(r => !!r);
    }

    // Set rides
    const newRides = { ...rides, [path]: newData };

    await this.setCacheState({ rides: newRides });

    // UPDATE SELECTED TRIPS IF ANY
    const { outboundTrip = null, returnTrip = null } = this.state.session;

    // console.log("[ON RIDES UPDATE] Rides update!");
    if (
      outboundTrip &&
      outboundTrip.id &&
      check.nonEmptyArray(newRides[path]) &&
      !!newRides[path].find(r => r.id === outboundTrip.id)
    ) {
      // console.log("[ON RIDES UPDATE] Outbound trip update!");
      const newTrip = newRides[path].find(r => r.id === outboundTrip.id);
      await this.setSession({
        outboundTrip: Object.assign({}, outboundTrip, newTrip)
      });
    }

    if (
      returnTrip &&
      returnTrip.id &&
      check.nonEmptyArray(newRides[path]) &&
      !!newRides[path].find(r => r.id === returnTrip.id)
    ) {
      // console.log("[ON RIDES UPDATE] Return trip update!");
      const newTrip = newRides[path].find(r => r.id === returnTrip.id);
      await this.setSession({
        returnTrip: Object.assign({}, returnTrip, newTrip)
      });
    }
  }

  async onExpandedRideDataUpdate(origin, destination, date, rideId, data) {
    const {
      cache: { rides = {}, expandedRidesData = {} }
    } = this.state;
    const path = `${origin}/${destination}/${date}`;
    const ridesForPath = rides[path];

    // Merge expanded fields on new rides data if any
    let newRidesForPath = ridesForPath;
    if (check.nonEmptyArray(ridesForPath)) {
      newRidesForPath = ridesForPath
        .map(r => {
          if (rideId === r.id) {
            return { ...r, ...(data || {}) };
          }
          return r;
        })
        .filter(r => !!r);
    }

    // Update rides
    const newRides = { ...rides, [path]: newRidesForPath };

    // Update expanded rides data
    const newExpandedRidesData = { ...expandedRidesData, [rideId]: data || {} };

    await this.setCacheState({
      rides: newRides,
      expandedRidesData: newExpandedRidesData
    });

    // UPDATE SELECTED TRIPS IF ANY
    const { outboundTrip = null, returnTrip = null } = this.state.session;

    // console.log("[ON EXPANDED RIDES UPDATE] Rides update!");
    if (
      outboundTrip &&
      outboundTrip.id &&
      check.nonEmptyArray(newRides[path]) &&
      !!newRides[path].find(r => r.id === outboundTrip.id)
    ) {
      // console.log("[ON EXPANDED RIDES UPDATE] Outbound trip update!");
      const newTrip = newRides[path].find(r => r.id === outboundTrip.id);
      await this.setSession({
        outboundTrip: Object.assign({}, outboundTrip, newTrip)
      });
    }

    if (
      returnTrip &&
      returnTrip.id &&
      check.nonEmptyArray(newRides[path]) &&
      !!newRides[path].find(r => r.id === returnTrip.id)
    ) {
      // console.log("[ON EXPANDED RIDES UPDATE] Return trip update!");
      const newTrip = newRides[path].find(r => r.id === returnTrip.id);
      await this.setSession({
        returnTrip: Object.assign({}, returnTrip, newTrip)
      });
    }
  }

  async onUserReadableDataUpdate(data) {
    await this.setState({
      userReadable: Object.assign(Busea.defaultUserReadableState(), data)
    });
  }

  // When we receive new information from the remote server, set it to local state
  async onUserDataUpdate(data) {
    await this.setState({
      user: Object.assign(Busea.defaultUserState(), data || {})
    });

    // Update language used by app if it was set remotely
    const {
      user: { lang }
    } = this.state;
    if (lang && lang !== translationManager.getCurrentLanguage()) {
      await this.setUserStateLanguage(lang);
    }
  }

  async onOnlineStatusChange(isOnline) {
    await this.setState({ online: isOnline });
  }

  async setUserStateLanguage(lang) {
    const { user } = this.state;
    await this.setState({
      user: { ...user, lang: translationManager.setLanguage(lang) }
    });
  }

  getAppState() {
    return this.state || Busea.defaultState();
  }

  getRidesData(origin, destination, date) {
    const {
      cache: { rides = {} }
    } = this.state;
    const path = `${origin}/${destination}/${date}`;

    return rides[path] || [];
  }

  async setState(state, callback = () => {}) {
    return new Promise((resolve, reject) => {
      try {
        // Save state in memory
        super.setState(state, (...args) => {
          // Save state in localStorage
          Busea.saveState(this.state);

          // Return
          callback(...args);
          resolve(...args);
        });
      } catch (err) {
        reject(err);
      }
    });
  }

  async setLocalState(newState) {
    const { local } = this.state;

    await this.setState({
      local: { ...local, ...newState }
    });
  }

  async setSession(newState) {
    const { session } = this.state;

    // console.log("Calling setSession(" + JSON.stringify(newState) + ") from: ");
    // console.trace();

    await this.setState({
      session: { ...session, ...newState }
    });
  }

  async setUserState(newState) {
    const {
      user,
      local: { loggedIn }
    } = this.state;

    const newUser = { ...user, ...newState };
    await this.setState({
      user: newUser
    });

    // Save state in DB
    if (loggedIn) {
      await this.pushUserStateToDb();
    }

    // Set language if language was changed
    const {
      user: { lang }
    } = this.state;
    if (lang && lang !== translationManager.getCurrentLanguage()) {
      await this.setUserStateLanguage(lang);
    }
  }

  async setCacheState(newState) {
    const { cache } = this.state;

    await this.setState({
      cache: { ...cache, ...newState }
    });
  }

  // Used to set userReadable while user is logged out
  // Necessary to keep track of orders passed anonymously
  async setAnonymousUserReadableIfAppropriate(data) {
    const {
      local: { loggedIn }
    } = this.state;

    if (loggedIn) {
      return;
    }

    await this.setState({
      userReadable: Object.assign(Busea.defaultUserReadableState(), data)
    });
  }

  // Ask server to recap list of orders and saved cards and update userReadable
  async forceUserReadableRefresh() {
    await firebasePost(
      firebase,
      `https://${functionsEndpoint}/api/user/refresh-users-readable`,
      {}
    );
  }

  async logOut() {
    await firebase.auth().signOut();
    await this.onLogout();
  }

  async pushUserStateToDb() {
    const {
      user,
      local: { loggedIn, uid }
    } = this.state;

    if (!loggedIn) {
      console.error(
        "[Busea] Trying to update firebase user state while not logged in. Aborting..."
      );
      return;
    }

    await firebase
      .database()
      .ref(`/users/${uid}/`)
      .set(user); // FIXME: Should be 'update' ?
  }

  render() {
    const {
      online,
      session: {
        hasSeenWelcomeScreen,
        selectedDeparture = null,
        selectedArrival = null,
        outDate = null,
        returnDate = null,
        outboundTrip = null,
        returnTrip = null
      },
      local: { loggedIn, uid },
      userReadable: { isAuthorizedTester = false },
      user: { email: userEmail = null }
    } = this.state;
    const {
      nativeSignIn = () => {},
      nativeSignInError,
      nativeSignInPending,
      remoteConsoleChannel,
      startDebug = () => {},
      stopDebug = () => {}
    } = this.props;

    const homePage =
      hasSeenWelcomeScreen || !hasOnboarding ? "/book/" : "/onboarding/"; //  '/welcome/';
    // If a path is specified in window.location.hash, redirect there
    /*
    if (window && window.location && check.nonEmptyString(window.location.hash)) {
      const h = window.location.hash.substr(1);
      if (h.indexOf('/') === 0) {
        homePage = h;
      }
    }
    */

    const pageProps = {
      sdk: this.sdk,
      appState: this.state,
      setSession: this.setSession,
      setUserState: this.setUserState,
      setAnonymousUserReadableIfAppropriate: this
        .setAnonymousUserReadableIfAppropriate,
      logOut: this.logOut,
      nativeSignIn,
      nativeSignInError,
      nativeSignInPending,
      remoteConsoleChannel,
      startDebug,
      stopDebug
    };

    const listeners = [
      <RoutesAndLocationsListener
        key="RoutesAndLocationsListener"
        onRoutesUpdate={this.onRoutesUpdate}
        onLocationsUpdate={this.onLocationsUpdate}
      />,
      <AuthListener
        key="AuthListener"
        online={online}
        onAuthStateChange={this.onAuthStateChange}
      />,
      <OnlineListener
        key="OnlineListener"
        onOnlineStatusChange={this.onOnlineStatusChange}
      />,
      <UserDataListener
        key="UserDataListener"
        online={online}
        userId={uid}
        userEmail={userEmail}
        loggedIn={loggedIn}
        onUserDataUpdate={this.onUserDataUpdate}
        onUserReadableDataUpdate={this.onUserReadableDataUpdate}
      />
    ];

    if (selectedDeparture && selectedArrival) {
      if (outDate) {
        listeners.push(
          <RidesListener
            key="rides-listener-out"
            origin={selectedDeparture}
            destination={selectedArrival}
            date={outDate}
            onRidesUpdate={this.onRidesUpdate}
          />
        );

        if (outboundTrip) {
          listeners.push(
            <ExpandedRideListener
              key="ExpandedRideListener:outbound"
              origin={selectedDeparture}
              destination={selectedArrival}
              date={outDate}
              rideId={outboundTrip.id}
              onExpandedRideDataUpdate={this.onExpandedRideDataUpdate}
            />
          );
        }
      }
      if (returnDate) {
        listeners.push(
          <RidesListener
            key="rides-listener-return"
            origin={selectedArrival}
            destination={selectedDeparture}
            date={returnDate}
            onRidesUpdate={this.onRidesUpdate}
          />
        );

        if (returnTrip) {
          listeners.push(
            <ExpandedRideListener
              key="ExpandedRideListener:return"
              origin={selectedArrival}
              destination={selectedDeparture}
              date={returnDate}
              rideId={returnTrip.id}
              onExpandedRideDataUpdate={this.onExpandedRideDataUpdate}
            />
          );
        }
      }
    }

    let content = null;
    if (!isAuthorizedTester && BETA_ONBOARDING_DISPLAYED && hasOnboarding) {
      content = (
        <Switch>
          <Route
            path="/login/"
            exact
            render={props => <LoginPage {...props} {...pageProps} />}
          />
          <Route
            path="/login/forgot-password/"
            exact
            render={props => <ForgotPasswordPage {...props} {...pageProps} />}
          />
          <Route
            path="/create-account/"
            exact
            render={props => <SignUpPage {...props} {...pageProps} />}
          />
          <Route
            path="/create-account/done/"
            exact
            render={props => <SignUpOkPage {...props} {...pageProps} />}
          />
          <Route path="/onboarding/">
            <BetaOnboarding {...pageProps} />
          </Route>
          <Route path="/">
            <BetaOnboarding {...pageProps} />
          </Route>
        </Switch>
      );
    } else {
      content = (
        <Switch>
          {hasWelcomePage && (
            <Route
              path="/welcome/"
              exact
              render={props => <WelcomePage {...props} {...pageProps} />}
            />
          )}
          {!hasWelcomePage && (
            <Route
              path="/welcome/"
              exact
              render={props => <Redirect to="/book/" />}
            />
          )}
          {hasOnboarding && (
            <Route
              path="/onboarding/"
              exact
              render={props => (
                <BetaOnboarding
                  {...props}
                  {...pageProps}
                  next="/book/"
                  byPassWaitingList
                  onNext={() => this.setSession({ hasSeenWelcomeScreen: true })}
                />
              )}
            />
          )}
          {/*
            // Disabling this as it doesn't work for all phone numbers...
            <Route
              path="/login/by-phone"
              exact
              render={(props) => <LoginByPhonePage {...props} {...pageProps} />}
            />
            */}

          <Route
            path="/login/"
            exact
            render={props => <LoginPage {...props} {...pageProps} />}
          />
          <Route
            path="/logout"
            exact
            render={props => <LogoutPage {...props} {...pageProps} />}
          />
          <Route
            path="/logout/:nextPath"
            exact
            render={props => <LogoutPage {...props} {...pageProps} />}
          />
          <Route
            path="/login/forgot-password/"
            exact
            render={props => <ForgotPasswordPage {...props} {...pageProps} />}
          />
          <Route
            path="/create-account/"
            exact
            render={props => <SignUpPage {...props} {...pageProps} />}
          />
          <Route
            path="/create-account/done/"
            exact
            render={props => <SignUpOkPage {...props} {...pageProps} />}
          />
          <Route
            path="/book/"
            exact
            render={props => <BookingFormPage {...props} {...pageProps} />}
          />
          <Route
            path="/book/idle-form"
            exact
            render={props => (
              <BookingFormPage {...props} {...pageProps} isIdle={true} />
            )}
          />
          <Route
            path="/book/details/departure-date"
            exact
            render={props => (
              <DepartureDatePopup
                {...props}
                {...pageProps}
                getRidesData={this.getRidesData}
              />
            )}
          />
          <Route
            path="/book/details/return-date"
            exact
            render={props => (
              <ReturnDatePopup
                {...props}
                {...pageProps}
                getRidesData={this.getRidesData}
              />
            )}
          />
          <Route
            path="/settings/passengers"
            exact
            render={props => (
              <EditPassengersPage
                {...props}
                {...pageProps}
                getRidesData={this.getRidesData}
              />
            )}
          />
          <Route
            path="/settings/passengers/add"
            exact
            render={props => (
              <AddPassengerPopup
                {...props}
                {...pageProps}
                getRidesData={this.getRidesData}
              />
            )}
          />
          <Route
            path="/settings/passengers/add/self"
            exact
            render={props => (
              <AddPassengerPopup
                {...props}
                {...pageProps}
                getRidesData={this.getRidesData}
                self
              />
            )}
          />
          <Route
            path="/settings/passengers/:passengerId/edit"
            exact
            render={props => (
              <EditPassengerPopup
                {...props}
                {...pageProps}
                getRidesData={this.getRidesData}
              />
            )}
          />
          <Route
            path="/book/out/"
            exact
            render={props => (
              <OutTripsListPage
                {...props}
                {...pageProps}
                getRidesData={this.getRidesData}
              />
            )}
          />
          <Route
            path="/book/out/select/"
            exact
            render={props => <OutTripDetailsPage {...props} {...pageProps} />}
          />
          <Route
            path="/book/out/select/seats/"
            exact
            render={props => (
              <OutTripSeatSelectionPage {...props} {...pageProps} />
            )}
          />
          <Route
            path="/book/out/select/stops/"
            exact
            render={props => <OutTripStopsPage {...props} {...pageProps} />}
          />
          <Route
            path="/book/return/"
            exact
            render={props => (
              <ReturnTripsListPage
                {...props}
                {...pageProps}
                getRidesData={this.getRidesData}
              />
            )}
          />
          <Route
            path="/book/return/select/"
            exact
            render={props => (
              <ReturnTripDetailsPage {...props} {...pageProps} />
            )}
          />
          <Route
            path="/book/return/select/seats/"
            exact
            render={props => (
              <ReturnTripSeatSelectionPage {...props} {...pageProps} />
            )}
          />
          <Route
            path="/book/return/select/stops/"
            exact
            render={props => <ReturnTripStopsPage {...props} {...pageProps} />}
          />
          <Route
            path="/book/passenger-details/"
            exact
            render={props => <PassengerDetailsPage {...props} {...pageProps} />}
          />
          <Route
            path="/book/passenger-details/add"
            exact
            render={props => (
              <PassengerDetailsAddPage
                {...props}
                {...pageProps}
                getRidesData={this.getRidesData}
              />
            )}
          />
          <Route
            path="/book/passenger-details/:passengerId/"
            exact
            render={props => <PassengerDetailsPage {...props} {...pageProps} />}
          />
          <Route
            path="/book/billing-details/"
            exact
            render={props => <BillingDetailsPage {...props} {...pageProps} />}
          />
          <Route
            path="/book/covid-info"
            exact
            render={props => <CovidInfoPage {...props} {...pageProps} />}
          />
          <Route
            path="/book/checkout/"
            exact
            render={props => <CheckoutPage {...props} {...pageProps} />}
          />
          <Route
            path="/book/checkout/pay/"
            exact
            render={props => (
              <CreditCardPaymentPage
                newCard={false}
                {...props}
                {...pageProps}
              />
            )}
          />
          <Route
            path="/book/checkout/pay/new-card"
            exact
            render={props => (
              <CreditCardPaymentPage newCard {...props} {...pageProps} />
            )}
          />
          <Route
            path="/book/in-progress/:orderId/"
            exact
            render={props => (
              <BookingInProgressPage {...props} {...pageProps} />
            )}
          />
          <Route
            path="/book/booking-error/:orderId/"
            exact
            render={props => (
              <BookingErrorPage timeout={false} {...props} {...pageProps} />
            )}
          />
          <Route
            path="/book/booking-error/:orderId/timeout"
            exact
            render={props => (
              <BookingErrorPage timeout {...props} {...pageProps} />
            )}
          />
          <Route
            path="/book/booking-confirmed/"
            exact
            render={props => <BookingConfirmedPage {...props} {...pageProps} />}
          />
          <Route
            path="/orders/"
            exact
            render={props => <OrdersPage {...props} {...pageProps} />}
          />
          <Route
            path="/orders/:orderId/"
            exact
            render={props => <OrdersPage {...props} {...pageProps} />}
          />
          <Route
            path="/tickets/:ticketId/"
            exact
            render={props => <TicketDetailsPage {...props} {...pageProps} />}
          />
          <Route
            path="/tickets/:ticketId/stops/"
            exact
            render={props => <TicketStopsPage {...props} {...pageProps} />}
          />
          <Route
            path="/tickets/:ticketId/boarding-pass/:passengerIndex/"
            exact
            render={props => (
              <TicketBoardingPassPage {...props} {...pageProps} />
            )}
          />
          <Route
            path="/tickets/:ticketId/boarding-pass/"
            exact
            render={props => (
              <TicketBoardingPassPage {...props} {...pageProps} />
            )}
          />
          <Route
            path="/settings/"
            exact
            render={props => <SettingsPage {...props} {...pageProps} />}
          />
          <Route
            path="/settings/language/"
            exact
            render={props => <LanguageSettingsPage {...props} {...pageProps} />}
          />
          <Route path="/">
            <Redirect to={homePage} />
          </Route>
        </Switch>
      );
    }

    return (
      <Router>
        <RouteBroadcaster>
          <LastLocationProvider>
            <div
              style={{
                minWidth: "100vw",
                minHeight: "100vh",
                padding: 0,
                margin: 0
              }}
            >
              {listeners}
              {content}
            </div>
          </LastLocationProvider>
        </RouteBroadcaster>
      </Router>
    );
  }
}
