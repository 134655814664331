import React from "react";
import withErrorManagementOnTripsDeletion from "./shared/withErrorManagementOnTripsDeletion";
import withBookingProcessController from "./shared/withBookingProcessController";
import { CovidInfoPage as CovidInfoPageUI } from "../../../lib/theme";
const CovidInfoPage = (props) => {
  const {
    sdk,
    goBack = () => {},
    goToLastAllowedStep = () => {},
    history,
  } = props;

  return (
    <CovidInfoPageUI
      sdk={sdk}
      defaultValue={sdk.covid19.getCovidInfo()}
      validateData={(data) => sdk.covid19.isCovid19InfoValid(data)}
      onSubmit={async (covidInfo) => {
        await sdk.covid19.setCovidInfo(covidInfo);
        goToLastAllowedStep();
      }}
      onBack={goBack}
      history={history}
    />
  );
};

export default withBookingProcessController(
  withErrorManagementOnTripsDeletion(CovidInfoPage)
);
