import React from "react";
import { translationManager } from "../../../lib/TranslationManager";
import { LanguageSettingsPage as LanguageSettingsPageUI } from "../../../lib/theme";
import withSettingsWidgetProps from "./shared/withSettingsWidgetProps";

const LanguageSettingsPageContainer = (props) => {
  const {
    appState: {
      user: { lang = null },
    },
    setUserState = () => {},
    history,
  } = props;

  const onLangChange = (newLang) => {
    if (newLang !== lang) {
      setUserState({ lang: translationManager.setLanguage(newLang) });
    }
  };

  const onBack = () => {
    history.push("/settings/");
  };

  return (
    <LanguageSettingsPageUI
      {...props}
      lang={lang || "en"}
      onLangChange={onLangChange}
      onBack={onBack}
    />
  );
};
export default withSettingsWidgetProps(LanguageSettingsPageContainer);
