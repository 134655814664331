import React, { Component } from "react";
import firebase from "firebase";
import check from "check-types";
import {
  ForgotPasswordLinkSentPage,
  ForgotPasswordPage as ForgotPasswordPageUI,
} from "../../../lib/theme";
import {
  translationManager,
  translatorForNamespace,
} from "../../../lib/TranslationManager";

export default class WelcomePage extends Component {
  constructor(...args) {
    super(...args);

    this.state = {
      ok: false,
      error: null,
      email: this.getCurrentAppEmail(),
    };

    // Bindings
    this.onEmailChange = this.onEmailChange.bind(this);
    this.onResetPassword = this.onResetPassword.bind(this);
  }

  async onResetPassword(email) {
    const { setUserState = () => {} } = this.props;

    // Reset password in Firebase
    // FIXME: Change return URL if embedded on mobile
    try {
      const auth = firebase.auth();
      auth.languageCode = translationManager.getCurrentLanguage();
      await auth.sendPasswordResetEmail(email, {
        url: window.location.origin,
      });
      this.setState({ ok: true });
    } catch (error) {
      this.setState({ error });
    }

    // Update the user's email
    await setUserState({ email });
  }

  onEmailChange(email) {
    this.setState({ email });
  }

  getCurrentAppEmail() {
    const { appState } = this.props;
    let email;
    if (appState.user && check.nonEmptyString(appState.user.email)) {
      ({ email } = appState.user);
    }

    return email;
  }

  render() {
    const { ok, error = null, email } = this.state;
    const { history } = this.props;

    if (ok) {
      return (
        <ForgotPasswordLinkSentPage
          translatorForNamespace={translatorForNamespace}
          onBack={() => history.push("/login/")}
          email={email}
        />
      );
    }

    return (
      <ForgotPasswordPageUI
        translatorForNamespace={translatorForNamespace}
        error={error}
        onBack={() => history.push("/login/")}
        email={email}
        onEmailChange={this.onEmailChange}
        onResetPassword={this.onResetPassword}
      />
    );
  }
}
