import React from "react";
import { GenericTripsDetailsPage as GenericTripDetailsPageUI } from "../../../../lib/theme";
import withErrorManagementOnTripsDeletion from "./withErrorManagementOnTripsDeletion";
import withBookingProcessController from "../shared/withBookingProcessController";

const GenericTripDetailsPage = ({
  selectedTrip,
  isReturn,
  showSeatsLink,
  showStopsLink,

  confirmLink,
  goBack = () => {},

  sdk,
  appState,
  appState: {
    session: {
      returnDate,
      numberOfPassengers,
      sessionId,

      selectedDeparture,
      selectedArrival,
      outDate,

      outboundTrip,
    },
  },
  setTrip = () => {},
  history,
}) => {
  return (
    <GenericTripDetailsPageUI
      sdk={sdk}
      isOneWayTrip={!sdk.bookingProcess.isCurrentOrderARoundTrip()}
      numberOfPassengers={numberOfPassengers}
      selectedPassengers={sdk.passengers.getPassengersSelectedForNextTrip()}
      {...{
        selectedTrip,
        isReturn,
        showSeatsLink,
        showStopsLink,
        confirmLink,
        onBack: goBack,
        setTrip,
        sessionId,
        history,
      }}
      appState={appState}
      origin={isReturn ? selectedArrival : selectedDeparture}
      destination={isReturn ? selectedDeparture : selectedArrival}
      date={isReturn ? outDate : returnDate}
      numberOfSeatsToSelect={numberOfPassengers}
    />
  );
};

export default withBookingProcessController(
  withErrorManagementOnTripsDeletion(GenericTripDetailsPage)
);
