import React from "react";
import { Redirect } from "react-router-dom";
import GenericTripStopsPage from "./shared/GenericTripStopsPage";

const ReturnTripStopsPage = (props) => {
  const {
    appState: {
      session: { selectedDeparture, selectedArrival, returnDate, returnTrip },
      cache: { rides = [] },
    },
    history,
  } = props;

  if (!returnDate) {
    return <Redirect to="/book/return/select/" />;
  }

  return (
    <GenericTripStopsPage
      appState={props.appState}
      origin={selectedArrival}
      destination={selectedDeparture}
      date={returnDate}
      selectedTrip={returnTrip}
      isReturn={false}
      ridesCache={rides}
      history={history}
    />
  );
};

export default ReturnTripStopsPage;
