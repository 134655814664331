import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { WelcomePage as WelcomePageUI } from "../../../lib/theme";
import { translatorForNamespace } from "../../../lib/TranslationManager";

export default class WelcomePage extends Component {
  async componentDidMount() {
    const { setSession = () => {} } = this.props;
    await setSession({ hasSeenWelcomeScreen: true });
  }

  render() {
    const {
      appState: {
        local: { loggedIn },
      },
      history,
    } = this.props;

    if (loggedIn) {
      return <Redirect to="/book/" />;
    }

    return (
      <WelcomePageUI
        connectLink="/login/"
        registerLink="/create-account/"
        bookLink="/book/"
        onBack={() => {
          history.goBack();
        }}
        translatorForNamespace={translatorForNamespace}
      />
    );
  }
}
