import React from "react";
import { AddPassengerPopup as AddPassengerPopupUI } from "../../../lib/theme";
import withSettingsWidgetProps from "./shared/withSettingsWidgetProps";

const AddPassengerPopup = (props) => {
  const { sdk, history, self = false } = props;

  const onAdd = async (p) => {
    if (!sdk.passengers.isBasicPassengerInfoValid(p)) {
      console.log("Aborting add as basic info is not here");
      return;
    }

    // Create user
    await sdk.passengers.createPassengerSelectIt(p, self);

    // Go back
    if (self) {
      history.push("/settings/");
    } else {
      history.push("/settings/passengers");
    }
  };

  return (
    <AddPassengerPopupUI
      {...props}
      sdk={sdk}
      onAdd={onAdd}
      onBack={
        self
          ? () => history.push("/settings/")
          : () => history.push("/settings/passengers")
      }
    />
  );
};
export default withSettingsWidgetProps(AddPassengerPopup);
