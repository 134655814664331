import React from "react";
import { Redirect } from "react-router-dom";
import GenericTripDetailsPage from "./shared/GenericTripDetailsPage";

const ReturnTripDetailsPage = (props) => {
  const {
    appState: {
      session: { returnTrip },
    },
    history,
    setSession = () => {},
  } = props;

  if (!returnTrip) {
    return <Redirect to="/book/checkout/" />;
  }

  return (
    <GenericTripDetailsPage
      {...props}
      selectedTrip={returnTrip}
      isReturn
      showSeatsLink="/book/return/select/seats/"
      showStopsLink="/book/return/select/stops/"
      confirmLink="/book/checkout/"
      onBack={history.goBack}
      setTrip={async (trip) => {
        await setSession({ returnTrip: trip });
      }}
    />
  );
};

export default ReturnTripDetailsPage;
