import check from "check-types";
import autoBind from "auto-bind";
import arrayFlatten from "array-flatten";
import arrayDiff from "simple-array-diff";
import firebase from "firebase";
import arrayUnique from "array-unique";
import { firebasePost } from "@seafront/firebase-api-client";
import { Mutex } from "async-mutex";
import isJson from "is-json";
import withSeatSelectorProps from "../../components/containers/pages/shared/withSeatSelectorProps";
import { functionsEndpoint } from "../../config/firebase.json";
import superagent from "superagent";
import "superagent-queue";

const parseSuperAgentResponse = (response) => {
  if (response.body) {
    return response.body;
  }
  if (check.nonEmptyString(response.text)) {
    if (isJson(response.text)) {
      return JSON.parse(response.text);
    }
    return response.text;
  }

  return response.body;
};

const firebasePostWithQueue = async (firebase, url, body = {}) => {
  const { currentUser } = firebase.auth();
  const authToken = currentUser
    ? await firebase.auth().currentUser.getIdToken(/* forceRefresh */ true)
    : null;

  let request = superagent
    .post(url)
    .send(body)
    .queue("requests")
    .set("accept", "json");

  if (authToken) {
    request = request.set("Authorization", `Bearer ${authToken}`);
  }

  const response = await request;
  return parseSuperAgentResponse(response);
};

const SERVER_REQUEST_TIMEOUT_MS = 1 * 60 * 1000;

export default class SeatsSdk {
  constructor(sdk) {
    this.sdk = sdk;
    this.lastServerRequestDateOutbound = null;
    this.lastServerRequestDateReturn = null;

    this.mutex = new Mutex();

    autoBind(this);
  }

  withSeatSelectorProps(...args) {
    return withSeatSelectorProps(...args);
  }

  // Deprecated
  getSelectedSeatsForOutboundTrip() {
    return this.getSelectedSeatsData(false);
  }

  // Deprecated
  getSelectedSeatsForReturnTrip() {
    return this.getSelectedSeatsData(true);
  }

  getNumbersOfSeatsToSelect() {
    const {
      session: { numberOfPassengers },
    } = this.sdk.getAppState();
    return numberOfPassengers || 0;
  }

  hasSelectedAllOutboundTripSeats() {
    // Handle manual seat preferences 
    if (this.isSeatSelectionManual(false)) {
      return this.hasUserFilledManualSeatSelectionPreferences(false)
    }

    // Handle classic seat selection
    return (
      this.getSelectedSeatsForOutboundTrip().length ===
      this.getNumbersOfSeatsToSelect()
    );
  }

  hasSelectedAllReturnTripSeats() {
    // Handle manual seat preferences 
    if (this.isSeatSelectionManual(false)) {
      return this.hasUserFilledManualSeatSelectionPreferences(true)
    }

   // Handle classic seat selection
    return (
      this.getSelectedSeatsForReturnTrip().length ===
      this.getNumbersOfSeatsToSelect()
    );
  }

  hasSelectedTrip(forReturnTrip) {
    const selectedTrip = this._getTripObj(forReturnTrip);
    return check.nonEmptyObject(selectedTrip);
  }

  isRequestToServerPending(forReturnTrip = false) {
    let lastDate = forReturnTrip
      ? this.lastServerRequestDateReturn
      : this.lastServerRequestDateOutbound;

    return lastDate && Date.now() - lastDate < SERVER_REQUEST_TIMEOUT_MS;
  }

  allSeatsSelectedAndValid(checkServerErrors = false) {
    const {
      session: { returnDate },
    } = this.sdk.getAppState();

    const valid =
      this.hasSelectedAllOutboundTripSeats() &&
      (!returnDate || this.hasSelectedAllReturnTripSeats());

    if (!valid || !checkServerErrors) {
      return valid;
    }

    if (
      !check.emptyArray(this.selectedSeatsWithErrors(false)) ||
      (returnDate && !check.emptyArray(this.selectedSeatsWithErrors(true)))
    ) {
      return false;
    }

    return true;
  }

  

  getSeatMatrix(forReturnTrip = false) {
    // Handle manual seat preferences 
    if (this.isSeatSelectionManual(forReturnTrip)) {
      return 'manual';
    }

    const selectedTrip = this._getTripObj(forReturnTrip);
    const seatMatrix = selectedTrip ? selectedTrip.seatMatrix || [] : null;

    // Stop here is trip is not selected or seatMatrix isn't available
    if (!selectedTrip || !check.nonEmptyArray(seatMatrix)) {
      return seatMatrix;
    }

    // Get held seats to alaways display them as available in the seat map
    // When a seat is really not available for the user, it is removed from the selectedSeats array
    const heldSeats = this.getSelectedSeats(forReturnTrip);
    if (!check.nonEmptyArray(heldSeats)) {
      return seatMatrix;
    }

    seatMatrix.forEach((l1) => {
      l1.forEach((l2) => {
        if (check.nonEmptyArray(l2)) {
          l2.forEach((l3) => {
            if (
              check.nonEmptyObject(l3) &&
              l3.number &&
              heldSeats.includes(l3.number)
            ) {
              l3.available = true;
            }
          });
        } else if (
          check.nonEmptyObject(l2) &&
          l2.number &&
          heldSeats.includes(l2.number)
        ) {
          l2.available = true;
        }
      });
    });

    return seatMatrix;
  }

  getSelectedSeatsData(forReturnTrip = false) {
    // Manual seats selection 
    if (this.isSeatSelectionManual(forReturnTrip)) {
      return this.getSelectedSeats(forReturnTrip);
    }

    // Get selected seats
    const selectedSeats = this.getSelectedSeats(forReturnTrip);
    if (!check.nonEmptyArray(selectedSeats)) {
      return [];
    }

    // Get seat matrix
    const seatMatrix = this.getSeatMatrix(forReturnTrip);
    if (!check.nonEmptyArray(seatMatrix)) {
      return [];
    }

    // Return flattened seatMatrix filtered by seat number
    return arrayFlatten(seatMatrix).filter(
      (s) =>
        s.available &&
        s.exists &&
        check.integer(s.number) &&
        selectedSeats.includes(s.number)
    );
  }

  getSelectedSeats(forReturnTrip = false) {
    const { session } = this.sdk.getAppState();

    // Don't return any seats if the trip has not been selected
    if (!this.hasSelectedTrip(forReturnTrip)) {
      return [];
    }

    const data = session[this._sessionKeyForSeatsData(forReturnTrip)];
    
    // Manual seats selection 
    if (this.isSeatSelectionManual(forReturnTrip)) {
      if(!this._isManualSeatPreferencesObjectValid(data)) {
        return [];
      }

      return data
    }
    
    // Numeric seat selection
    if (
      !check.nonEmptyArray(data) ||
      !check.nonEmptyArray(data.filter((i) => check.integer(i)))
    ) {
      return [];
    }

    return data
      .filter((i) => check.integer(i))
      .slice(0, this.sdk.bookingProcess.getNumberOfPassengersForCurrentOrder());
  }

  async clearAllSelectedSeats() {
    return Promise.all([
      this.setSelectedSeats(false, []),
      this.setSelectedSeats(true, []),

      // FIXME: Should we clear seats on server here too?
    ]);
  }

  async setSelectedSeats(forReturnTrip = false, selectedSeatsNumbers = []) {
    const newSelectedSeatsNumbers = this._seatsNumbersOrObjToSeatsNumbers(
      selectedSeatsNumbers
    );

    const tripKey = this._sessionKeyForSeatsData(forReturnTrip);
    await this.sdk.setSession({ [tripKey]: newSelectedSeatsNumbers });
  }

  async addSelectedSeats(forReturnTrip = false, selectedSeatsNumbers = []) {
    return this.setSelectedSeats(forReturnTrip, [
      ...this.getSelectedSeats(forReturnTrip),
      ...selectedSeatsNumbers,
    ]);
  }

  async removeSelectedSeats(forReturnTrip = false, selectedSeatsNumbers = []) {
    return this.setSelectedSeats(
      forReturnTrip,
      this.getSelectedSeats(forReturnTrip).filter(
        (seat) => !selectedSeatsNumbers.includes(seat)
      )
    );
  }

  async toggleSelectedSeats(forReturnTrip = false, selectedSeatsNumbers = []) {
    const current = this.getSelectedSeats();
    const toToggle = this._seatsNumbersOrObjToSeatsNumbers(
      selectedSeatsNumbers
    );
    const toRemove = arrayDiff(current, toToggle).common;
    const toAdd = arrayDiff(current, toToggle).added;

    if (check.nonEmptyArray(toRemove)) {
      await this.removeSelectedSeats(forReturnTrip, toRemove);
    }
    if (check.nonEmptyArray(toAdd)) {
      await this.addSelectedSeats(forReturnTrip, toAdd);
    }
  }

  // Returns an array of selected seats which are not available on server
  // Returns [] if there is no error
  // Returns null when we're still loading the results
  selectedSeatsWithErrors(forReturnTrip = false) {
    if(this.isSeatSelectionManual(forReturnTrip)) {
      return [];
    } 

    const {
      session: { outboundSeatsErrors, returnSeatsErrors },
    } = this.sdk.getAppState();

    if (forReturnTrip) {
      return returnSeatsErrors;
    } else {
      return outboundSeatsErrors;
    }
  }

  async holdSeatsOnServer(forReturnTrip = false, rawSeats = []) {
    return this._seatsServerRequest(forReturnTrip, "hold", rawSeats);
  }

  async releaseSeatsOnServer(forReturnTrip = false, rawSeats = []) {
    return this._seatsServerRequest(forReturnTrip, "release", rawSeats);
  }

  async setSeatsOnServer(forReturnTrip = false, rawSeats = []) {
    return this._seatsServerRequest(forReturnTrip, "set", rawSeats);
  }

  async syncSeatsToServer(forReturnTrip = false) {
    // Don't run for manual seat selection 
    if(this.isSeatSelectionManual(forReturnTrip)) {
      return;
    }

    // Run for classic seat selection
    return this.setSeatsOnServer(
      forReturnTrip,
      this.getSelectedSeats(forReturnTrip)
    );
  }

  // ///////////////////////////////////////////////////////////////////////////
  async _seatsServerRequest(forReturnTrip, serverAction, rawSeats = []) {
    const {
      session: { sessionId },
    } = this.sdk.getAppState();

    const seats = check.integer(rawSeats) ? [rawSeats] : rawSeats;
    if (!check.array(seats)) {
      console.log(
        `Trying to call setSeatsOnServer() with invaid seats. Doing nothing.`
      );
      return null;
    }

    const selectedTrip = this._getTripObj(forReturnTrip);
    const routeId = selectedTrip ? selectedTrip.id : null;
    if (!check.nonEmptyString(routeId)) {
      console.log(
        `Trying to call _seatsServerRequest() with no routeId. Doing nothing.\nselectedTrip:${JSON.stringify(
          selectedTrip
        )}`
      );
      return null;
    }

    let response = null;
    const requestId = await this._openServerRequest(forReturnTrip);
    try {
      console.log("BEFORE request: " + JSON.stringify({ serverAction, seats }));
      response = await firebasePostWithQueue(
        firebase,
        `https://${functionsEndpoint}/api/v1/routes/${routeId}/seats/${serverAction}`,
        { sessionId, seats }
      );
      console.log("AFTER request: " + JSON.stringify({ serverAction, seats }));

      // If we are the last emitted request,
      // use response to check if some locally reserved seats are not available on the server
      if (
        !(await this._areOtherServerRequestsPending(forReturnTrip, requestId))
      ) {
        if (response && response.success && check.array(response.heldSeats)) {
          const remoteHeldSeats = response.heldSeats;
          const localHeldSeats = this.getSelectedSeats(forReturnTrip);
          const errors = arrayDiff(remoteHeldSeats, localHeldSeats).added;

          console.log("SETTING SEAT ERRORS TO: " + JSON.stringify(errors));

          if (forReturnTrip) {
            await this.sdk.setSession({ returnSeatsErrors: errors });
          } else {
            await this.sdk.setSession({ outboundSeatsErrors: errors });
          }
        }
      }
    } finally {
      await this._closeServerRequest(forReturnTrip, requestId);
    }

    // Return response
    return response;
  }

  _seatsNumbersOrObjToSeatsNumbers(seatObjs) {
    if (!check.nonEmptyArray(seatObjs)) {
      return [];
    }
    const numbers = seatObjs
      .map((s) => (check.nonEmptyObject(s) ? s.number || null : s))
      .filter((n) => check.integer(n));

    return arrayUnique(numbers);
  }

  _getTripObj(forReturnTrip = false) {
    const {
      session: { outboundTrip, returnTrip },
    } = this.sdk.getAppState();

    return forReturnTrip ? returnTrip : outboundTrip;
  }

  _seatsDataForNumbers(forReturnTrip = false, numbers) {
    return arrayFlatten(this.getSeatMatrix(forReturnTrip)).filter((s) =>
      numbers.includes(s.number)
    );
  }

  _sessionKeyForSeatsData(forReturnTrip = false) {
    return forReturnTrip ? "returnTripSeats" : "outboundTripSeats";
  }

  async _areOtherServerRequestsPending(forReturnTrip = false, serverRequestId) {
    return this.mutex.runExclusive(async () => {
      let lastDate = forReturnTrip
        ? this.lastServerRequestDateReturn
        : this.lastServerRequestDateOutbound;

      return lastDate > serverRequestId;
    });
  }

  async _openServerRequest(forReturnTrip = false) {
    return this.mutex.runExclusive(async () => {
      const now = Date.now();

      if (forReturnTrip) {
        this.lastServerRequestDateReturn = now;
      } else {
        this.lastServerRequestDateOutbound = now;
      }

      return now;
    });
  }

  async _closeServerRequest(forReturnTrip = false, serverRequestId) {
    await this.mutex.runExclusive(async () => {
      if (forReturnTrip) {
        if (this.lastServerRequestDateReturn <= serverRequestId) {
          this.lastServerRequestDateReturn = null;
        }
      } else {
        if (this.lastServerRequestDateOutbound <= serverRequestId) {
          this.lastServerRequestDateOutbound = null;
        }
      }
    });
  }

  async _updateTripData(forReturnTrip = false, data = {}) {
    const prevTrip = this._getTripObj(forReturnTrip);
    const newTrip = { ...(prevTrip || {}), ...(data || {}) };
    const tripKey = forReturnTrip ? "returnTrip" : "outboundTrip";

    await this.sdk.setSession({ [tripKey]: newTrip });
  }

  // ////////////////////////////////////////////////////////////////////
  // Manual seat selection
  // ////////////////////////////////////////////////////////////////////
  async setManualSeatSelection(forReturnTrip = false, data) {
    const tripKey = this._sessionKeyForSeatsData(forReturnTrip);
    await this.sdk.setSession({ [tripKey]: data });
  }

  isSeatSelectionManual(forReturnTrip = false) {
    const selectedTrip = this._getTripObj(forReturnTrip); 
    return selectedTrip && selectedTrip.seatsInformationAvailable === false
  }

  hasUserFilledManualSeatSelectionPreferences(forReturnTrip = false) {
    const data = this.getSelectedSeats(forReturnTrip);
    return this._isManualSeatPreferencesObjectValid(data);
  }

  _isManualSeatPreferencesObjectValid(data) {
    return (
      check.nonEmptyObject(data)
      && check.nonEmptyString(data.frontBack)
      && ['front','center','back'].includes(data.frontBack)
      && check.nonEmptyString(data.leftRight) 
      && ['left', 'right'].includes(data.leftRight)
    )
  }
  // ////////////////////////////////////////////////////////////////////

  
}
