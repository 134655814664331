import check from "check-types";
import { askForCovidInfo } from '../../config/app.json'

export default class Covid19Sdk {
  constructor(sdk) {
    this.sdk = sdk;
  }

  isCovid19InfoFilled() {
    if(!askForCovidInfo) {
      return true;
    }

    const {
      session: { covidInfo = {} },
    } = this.sdk.getAppState();

    return this.isCovid19InfoValid(covidInfo);
  }

  isCovid19InfoValid(covidInfo) {
    if (!check.nonEmptyObject(covidInfo)) {
      return false;
    }

    const { originAddress, destinationAddress } = covidInfo;

    if (
      !this.sdk.types.isAddressObjectValid(originAddress) ||
      !this.sdk.types.isAddressObjectValid(destinationAddress)
    ) {
      return false;
    }

    return true;
  }

  getCovidInfo() {
    const {
      session: { covidInfo = {} },
    } = this.sdk.getAppState();

    return covidInfo;
  }

  async setCovidInfo(covidInfo) {
    if (!this.isCovid19InfoValid(covidInfo)) {
      return;
    }

    this.sdk.setSession({
      covidInfo,
    });
  }

  getStringifiedInfo() {
    if(!askForCovidInfo) {
      return {
        originAddress: "No address specified",
        destinationAddress: "No address specified",
      };
    }

    if (!this.isCovid19InfoFilled()) {
      return null;
    }

    const {
      session: { covidInfo = {} },
    } = this.sdk.getAppState();

    return {
      originAddress: this.sdk.types.addressToString(covidInfo.originAddress),
      destinationAddress: this.sdk.types.addressToString(
        covidInfo.destinationAddress
      ),
    };
  }
}
