import React from "react";
import { OrderSetupPage } from "../../../lib/theme";
import withErrorManagementOnTripsDeletion from "./shared/withErrorManagementOnTripsDeletion";
import withBookingProcessController from "./shared/withBookingProcessController";

const CheckoutPage = (props) => {
  const {
    sdk,
    appState: {
      session: { outboundTrip, returnTrip },
      user: { passengerId },
    },
    goBack = () => {},
    history,
  } = props;

  const backLink = sdk.bookingProcess.isCurrentOrderARoundTrip()
    ? "/book/return/select/"
    : "/book/out/select/";

  return (
    <OrderSetupPage
      sdk={sdk}
      numberOfPassengers={sdk.bookingProcess.getNumberOfPassengersForCurrentOrder()}
      savedPassengers={sdk.passengers.getAllSavedPassengers()}
      selectedPassengers={sdk.passengers.getPassengersSelectedForNextTrip()}
      onPassengerToggle={(p) => sdk.passengers.togglePassengerForNextTrip(p)}
      userSavedCard={sdk.userSettings.getUserSavedCard()}
      payLink="/book/checkout/pay/"
      payAmount={sdk.bookingProcess.getCurrentOrderAmount()}
      outboundSeatErrors={sdk.seats.selectedSeatsWithErrors(false)}
      returnSeatErrors={sdk.seats.selectedSeatsWithErrors(false)}
      history={history}
      {...{
        passengerId,
        outboundTrip,
        returnTrip,
        onBack: () => history.push(backLink),
      }}
    />
  );
};
export default withBookingProcessController(
  withErrorManagementOnTripsDeletion(CheckoutPage)
);
