import React, { Component } from "react";
import check from "check-types";
import firstMatch from "first-match";
import { GenericStopsPage as GenericStopsPageUI } from "../../../../lib/theme";
import { translatorForNamespace } from "../../../../lib/TranslationManager";
import withErrorManagementOnTripsDeletion from "./withErrorManagementOnTripsDeletion";
import withBookingProcessController from "../shared/withBookingProcessController";

class GenericTripStopsPage extends Component {
  constructor(...args) {
    super(...args);

    // Bindings
    this.onBack = this.onBack.bind(this);
  }

  onBack() {
    const { history } = this.props;
    history.goBack();
  }

  getStops() {
    const {
      origin,
      destination,
      date,
      selectedTrip = null,
      ridesCache,
    } = this.props;

    const path = `${origin}/${destination}/${date}`;
    const rides = ridesCache[path] || [];

    console.log("GenericTripStopsPage: selectedTrip ==> ", selectedTrip);
    console.log("GenericTripStopsPage: rides ==> ", rides);
    const ride = firstMatch(rides, (r) => r.id === selectedTrip.id);
    const stops = ride ? ride.stops || [] : null;

    return stops;
  }

  render() {
    const { selectedTrip = null, sdk } = this.props;

    const stops = this.getStops();

    return (
      <>
        <GenericStopsPageUI
          {...{
            loading: !check.nonEmptyArray(stops),
            ticket: selectedTrip,
            stops,
            onBack: this.onBack,
            translatorForNamespace,
            isOneWayTrip: !sdk.bookingProcess.isCurrentOrderARoundTrip(),
          }}
        />
      </>
    );
  }
}

export default withBookingProcessController(
  withErrorManagementOnTripsDeletion(GenericTripStopsPage)
);
