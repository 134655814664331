import React, { Component } from "react";
import firebase from "firebase";
import check from "check-types";
import objectPick from "object.pick";
import { firebasePost } from "@seafront/firebase-api-client";
import { functionsEndpoint } from "../../../../config/firebase.json";

import {
  translationManager,
  translatorForNamespace,
} from "../../../../lib/TranslationManager";

// FIXME: Use UserSettings SDK for all these functions

const withSettingsWidgetProps = (WrappedComponent) =>
  class SettingsPage extends Component {
    constructor(...args) {
      super(...args);
      this.state = {
        cardBeingDeleted: false,
      };
    }

    render() {
      console.log("Props in withSettingsWidgetProps: ", this.props);
      const {
        sdk,
        appState: {
          local: { loggedIn = false },
          user: { lang = null, passengerId, email },
          userReadable: { cards },
        },
        setUserState = () => {},
        setAnonymousUserReadableIfAppropriate = () => {},
        history,
        remoteConsoleChannel = null,
        startDebug = () => {},
        stopDebug = () => {},
      } = this.props;
      const { cardBeingDeleted = false } = this.state;
      const version = process.env.REACT_APP_VERSION;

      const onLangChange = (newLang) => {
        if (newLang !== lang) {
          setUserState({ lang: newLang });
        }
      };

      const onLogOut = async () => {
        const { logOut = async () => {} } = this.props;
        await logOut();
        history.push("/welcome/");
      };

      const onConnect = () => {
        localStorage["busea.lastLocationBeforeLogin"] = "/settings/";
        history.push("/login/");
      };

      const onBack = () => {
        history.push("/book/");
        // history.goBack();
      };

      let card = null;
      if (!cardBeingDeleted && check.nonEmptyObject(cards)) {
        [card] = Object.values(cards);
      }

      const onDeleteCard = async () => {
        this.setState({ cardBeingDeleted: true });
        setTimeout(() => {
          this.setState({ cardBeingDeleted: false });
        }, 5000);

        if (loggedIn) {
          await firebasePost(
            firebase,
            `https://${functionsEndpoint}/api/user/cards/delete`
          );
        } else {
          await setAnonymousUserReadableIfAppropriate({ cards: {} });
        }
      };

      const onEditPassengers = () => {
        history.push("/settings/passengers");
      };

      const isDebugOn = !!remoteConsoleChannel;
      const onDebugToggle = () => {
        if (isDebugOn) {
          stopDebug();
        } else {
          startDebug();
        }
      };

      const supportedLanguages = objectPick(
        {
          en: "English",
          es: "Español",
          fr: "Français",
        },
        translationManager.supportedLanguages
      );

      return (
        <WrappedComponent
          {...this.props}
          loggedIn={loggedIn}
          selectedLanguage={supportedLanguages[lang] || "English"}
          card={card}
          version={version}
          savedPassengers={sdk.passengers.getAllSavedPassengers()}
          onEditPassengers={onEditPassengers}
          onConnect={onConnect}
          onLangChange={onLangChange}
          onLogOut={onLogOut}
          onBack={onBack}
          onDeleteCard={onDeleteCard}
          onDebugToggle={onDebugToggle}
          isDebugOn={isDebugOn}
          debugChannel={remoteConsoleChannel}
          userPassenger={sdk.passengers.getUserPassengerData()}
          userEmail={email}
          onLanguageEdit={() => history.push("/settings/language")}
          onUserPassengerEdit={() => {
            if (sdk.passengers.getUserPassengerData()) {
              history.push(`/settings/passengers/${passengerId}/edit/`);
            } else {
              history.push("/settings/passengers/add/self");
            }
          }}
          translationManager={translationManager}
          translatorForNamespace={translatorForNamespace}
          supportedLanguages={supportedLanguages}
        />
      );
    }
  };
export default withSettingsWidgetProps;
