import check from "check-types";
import autoBind from "auto-bind";
import BuseaApi from "@seafront/busea.api";
import { askForCovidInfo } from '../../config/app.json';

export default class BookingProcessSdk {
  constructor(sdk) {
    this.sdk = sdk;
    autoBind(this);
  }

  processStepsAndConditions() {
    const {
      session: { returnDate }
    } = this.sdk.getAppState();

    return {
      "/book": true,
      "/book/out": this.departureAndArrivalSelectedAndValid(),
      "/book/out/select": this.outboundRideSelectedAndValid(),
      // "/book/out/select/seats": true,
      // "/book/out/select/stops": true,

      ...(!returnDate
        ? {}
        : {
            "/book/return": this.sdk.seats.hasSelectedAllOutboundTripSeats(),
            "/book/return/select": this.returnRideSelectedAndValid()
            // "/book/return/select/seats": true,
            // "/book/return/select/stops": true,
          }),

      "/book/passenger-details": this.sdk.seats.allSeatsSelectedAndValid(),
      "/book/billing-details": this.sdk.passengers.allPassengersSelectedAndValid(),
      ...(askForCovidInfo ? {"/book/covid-info": this.sdk.billingDetails.areBillingDetailsValid()}: {}),
      "/book/checkout": askForCovidInfo ? this.sdk.covid19.isCovid19InfoFilled() : this.sdk.billingDetails.areBillingDetailsValid()
      // "/book/checkout/pay": true,
      // "/book/in-progress": true // FIXME: Handle routing here
      // "/book/booking-error": true, // FIXME: Handle routing here
      // "/book/booking-confirmed": true, // FIXME: Handle routing here
    };
  }

  prev(path = null) {
    return this.getStepPathForIndex(
      this.getStepIndexForPath(path || window.location.pathname) - 1
    );
  }

  next(path = null) {
    return this.getStepPathForIndex(
      Math.min(
        this.getStepIndexForPath(path || window.location.pathname) + 1,
        this.getMaxAllowedStepIndexWithCurrentSessionData()
      )
    );
  }

  max() {
    return this.getStepPathForIndex(
      this.getMaxAllowedStepIndexWithCurrentSessionData()
    );
  }

  goBack(history, path = null) {
    history.push(this.prev(path));
  }

  goForward(history, path = null) {
    history.push(this.next(path));
  }

  goToLastAllowedStep(history) {
    history.push(this.max());
  }

  processStepCanDisplay(path = null) {
    return (
      this.getStepIndexForPath(path || window.location.pathname) <=
      this.getMaxAllowedStepIndexWithCurrentSessionData()
    );
  }

  // Navigation helpers
  getMaxAllowedStepIndexWithCurrentSessionData() {
    const stepsConditions = this.processStepsAndConditions();
    const steps = Object.keys(stepsConditions);
    let maxAllowedStep = 0;
    let canContinue = true;

    steps.forEach((step, i) => {
      const conditionToDisplay = stepsConditions[step];
      canContinue = canContinue && conditionToDisplay;
      if (canContinue) {
        maxAllowedStep = i;
      }
    });

    return maxAllowedStep;
  }

  getStepIndexForPath(path) {
    let match = null;
    const stepsConditions = this.processStepsAndConditions();
    const steps = Object.keys(stepsConditions);

    steps.forEach((step, i) => {
      if (path.indexOf(step) === 0 && (!match || match.length <= step.length)) {
        match = step;
      }
    });

    if (!match) {
      return 0;
    }

    return steps.indexOf(match);
  }

  getStepPathForIndex(i) {
    const stepsConditions = this.processStepsAndConditions();
    const steps = Object.keys(stepsConditions);
    if (steps[i]) {
      return steps[i];
    }

    return steps[0];
  }

  // Validation helpers
  departureAndArrivalSelectedAndValid() {
    const {
      session: { selectedDeparture, selectedArrival, outDate }
    } = this.sdk.getAppState();
    return (
      check.nonEmptyString(selectedDeparture) &&
      check.nonEmptyString(selectedArrival) &&
      check.nonEmptyString(outDate)
    );
  }

  outboundRideSelectedAndValid() {
    const {
      session: { selectedDeparture, selectedArrival, outDate, outboundTrip },
      cache: { rides: ridesCache = {} }
    } = this.sdk.getAppState();

    if (!selectedDeparture || !selectedArrival || !outDate) {
      return false;
    }
    if (
      !check.nonEmptyObject(outboundTrip) ||
      !check.nonEmptyString(outboundTrip.id)
    ) {
      return false;
    }

    const path = `${selectedDeparture}/${selectedArrival}/${outDate}`;
    const outboundRides = ridesCache[path] || null;

    // No more rides available for outbound dates
    if (!check.nonEmptyArray(outboundRides)) {
      return false;
    }

    // Outbound ride selected but does not exist anymore
    if (!outboundRides.find(r => r.id === outboundTrip.id)) {
      return false;
    }

    return true;
  }

  returnRideSelectedAndValid() {
    const {
      session: { selectedDeparture, selectedArrival, returnDate, returnTrip },
      cache: { rides: ridesCache = {} }
    } = this.sdk.getAppState();

    if (!selectedDeparture || !selectedArrival) {
      return false;
    }

    // Returning true if there is no return ride to select
    if (!returnDate) {
      return true;
    }

    if (
      !check.nonEmptyObject(returnTrip) ||
      !check.nonEmptyString(returnTrip.id)
    ) {
      return false;
    }

    const returnPath = `${selectedArrival}/${selectedDeparture}/${returnDate}`;
    const returnRides = ridesCache[returnPath] || null;

    // No more rides available for outbound dates
    if (!check.nonEmptyArray(returnRides)) {
      return false;
    }

    if (!returnRides.find(r => r.id === returnTrip.id)) {
      return false;
    }

    return true;
  }

  // Misc
  getSelectedOutboundTrip() {
    const {
      session: { outboundTrip }
    } = this.sdk.getAppState();
    return outboundTrip;
  }

  getSelectedReturnTrip() {
    const {
      session: { returnTrip }
    } = this.sdk.getAppState();
    return returnTrip;
  }

  getNumberOfPassengersForCurrentOrder() {
    const {
      session: { numberOfPassengers }
    } = this.sdk.getAppState();
    return numberOfPassengers;
  }

  getCurrentOrderAmount() {
    const orderPricing = BuseaApi.getOrderPricing(
      this.getSelectedOutboundTrip(),
      this.getSelectedReturnTrip(),
      this.getNumberOfPassengersForCurrentOrder()
    );
    const payAmount = orderPricing.total;
    return payAmount;
  }

  isCurrentOrderARoundTrip() {
    const {
      session: { returnDate }
    } = this.sdk.getAppState();
    return !!returnDate;
  }
}
