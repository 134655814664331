import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import request from "request-promise";
import check from "check-types";
import firebase from "firebase";
import BuseaApi from "@seafront/busea.api";
import Script from "react-load-script";
import { StripeProvider } from "react-stripe-elements";
import { PaymentPage, ProcessingPaymentPage } from "../../../lib/theme";
import {
  translationManager,
  translatorForNamespace
} from "../../../lib/TranslationManager";
import stripeConfig from "../../../config/stripe.json";
import { functionsEndpoint } from "../../../config/firebase.json";
import withErrorManagementOnTripsDeletion from "./shared/withErrorManagementOnTripsDeletion";
import withBookingProcessController from "./shared/withBookingProcessController";

const withStripeScript = WrappedComponent =>
  class WithStripeScript extends Component {
    constructor(...args) {
      super(...args);
      this.state = {
        error: false,
        scriptLoaded: false
      };
    }

    render() {
      const { scriptLoaded, error } = this.state;
      return (
        <>
          <Script
            url="https://js.stripe.com/v3/"
            onError={() => this.setState({ error: true })}
            onLoad={() => this.setState({ scriptLoaded: true })}
          />
          <WrappedComponent
            {...this.props}
            stripeScriptLoaded={scriptLoaded}
            stripeScriptLoadError={error}
          />
        </>
      );
    }
  };

class CreditCardPaymentPage extends Component {
  constructor(...args) {
    super(...args);
    this.state = {
      processing: false,
      error: null,
      success: false,
      confirmedOrderId: null,
      loading: true
    };

    // Bindings
    this.onPaymentValidated = this.onPaymentValidated.bind(this);
  }

  async componentDidMount() {
    const {
      newCard = false,
      sdk,
      appState: {
        local: { loggedIn },
        userReadable: { cards },
        session: { outboundTrip = null }
      },
      history
    } = this.props;

    const { email } = sdk.billingDetails.getBillingDetails();

    // Redirect to home page if session has no order being booked
    if (!outboundTrip) {
      history.push("/book");
      return;
    }

    if (
      loggedIn &&
      check.nonEmptyObject(cards) &&
      !newCard &&
      sdk.billingDetails.areBillingDetailsValid() &&
      sdk.covid19.isCovid19InfoFilled()
    ) {
      console.log("Everything OK. Proceeding to payment");
      await this.onPaymentValidated(email, null);
    } else {
      console.log("Thinks NOK. NOT proceeding to payment");
      await this.setState({ loading: false });
    }
  }

  async onPaymentValidated(email, cardToken) {
    const {
      sdk,
      appState: {
        session: {
          sessionId = null,
          orderId = null,
          outboundTrip = null,
          returnTrip = null
        },
        local: { loggedIn },
        userReadable: { ordersById }
      },
      setSession = () => {},
      setAnonymousUserReadableIfAppropriate = () => {}
    } = this.props;

    const selectedPassengers = sdk.passengers.getPassengersSelectedForNextTrip();

    // Activate loading page
    await this.setState({
      loading: false,
      processing: true,
      error: null,
      success: false,
      confirmedOrderId: null
    });

    console.log("Card Token from Stripe: ", cardToken);

    const billingDetails = sdk.billingDetails.getBillingDetails() || {};

    // Process order
    const body = {
      // If we already created an order in this session,
      // communicate it to the server
      orderId: orderId || null,
      lang: translationManager.getCurrentLanguage(),
      sessionId,
      outboundRideId: outboundTrip.id,
      outboundSeats: sdk.seats.getSelectedSeats(false),
      returnRideId: returnTrip ? returnTrip.id : null,
      returnSeats: sdk.seats.getSelectedSeats(true),
      billingDetails,
      covid19Info: sdk.covid19.getStringifiedInfo(),
      passengerDetails: selectedPassengers,
      email,
      cardToken,
      amount: Math.round(
        BuseaApi.getOrderPricing(
          outboundTrip,
          returnTrip,
          selectedPassengers.length
        ).total * 100
      )
    };

    const headers = {};
    if (loggedIn && firebase.auth().currentUser) {
      const authToken = await firebase.auth().currentUser.getIdToken(true);
      headers.Authorization = `Bearer ${authToken}`;
    }

    try {
      console.log("⚙️Submitting reservation...");
      const response = await request({
        method: "post",
        uri: `https://${functionsEndpoint}/api/`,
        json: true,
        headers,
        body
      });

      console.log("✅Reservation OK: ", response);

      // Save orderId for future use
      // In case we must re-attempt payment or booking after
      const order = response;
      await setSession({
        orderId: order.id,
        lastOrderId: order.id
      });

      // If we're not logged in, keep order and card in userReadable until we log in
      await setAnonymousUserReadableIfAppropriate({
        ordersById: { ...(ordersById || {}), [order.id]: order },
        ...(check.nonEmptyObject(cardToken) &&
        check.nonEmptyObject(cardToken.card)
          ? {
              cards: {
                [`${cardToken.card.brand}_${cardToken.card.last4}`]: {
                  brand: cardToken.card.brand,
                  last4: cardToken.card.last4
                }
              }
            }
          : {})
      });

      // Indicate success
      await this.setState({
        processing: false,
        success: true,
        confirmedOrderId: order.id
      });
    } catch (error) {
      console.log(error);
      await this.setState({ processing: false, error });
    }
  }

  async setState(state) {
    return new Promise((resolve, reject) => {
      try {
        super.setState(state, resolve);
      } catch (err) {
        reject(err);
      }
    });
  }

  render() {
    const {
      error = null,
      processing = false,
      success = false,
      confirmedOrderId = null,
      loading = true
    } = this.state;
    const {
      sdk,
      appState: {
        local: { loggedIn },
        session: { outboundTrip, returnTrip, passengerDetails }
      },
      stripeScriptLoaded,
      stripeScriptLoadError,
      goBack = () => {}
    } = this.props;

    const selectedPassengers = sdk.passengers.getPassengersSelectedForNextTrip();

    if (stripeScriptLoadError) {
      return <Redirect to={`/book/checkout/`} />;
    }

    if (loading || !stripeScriptLoaded) {
      return (
        <ProcessingPaymentPage
          translatorForNamespace={translatorForNamespace}
        />
      );
    }

    // Redirect to previous steps if info is not right
    /*
    if (!outboundTrip) {
      return <Redirect to="/book/" />;
    }
    if (!check.nonEmptyArray(selectedPassengers)) {
      return <Redirect to="/  book/passenger-details/" />;
    }
    */

    let payAmount = 0;
    if (passengerDetails && outboundTrip) {
      payAmount = BuseaApi.getOrderPricing(
        outboundTrip,
        returnTrip,
        selectedPassengers.length
      ).total;
    }

    if (success) {
      return <Redirect to={`/book/in-progress/${confirmedOrderId}`} />;
    }

    if (!error && processing) {
      return (
        <ProcessingPaymentPage
          translatorForNamespace={translatorForNamespace}
        />
      );
    }

    return (
      <StripeProvider apiKey={stripeConfig.apiKey}>
        <PaymentPage
          numberOfPassengers={sdk.bookingProcess.getNumberOfPassengersForCurrentOrder()}
          payAmount={payAmount}
          error={error}
          email={sdk.billingDetails.getBillingDetails().email}
          loggedIn={loggedIn}
          onToken={this.onPaymentValidated}
          onBack={goBack}
          translatorForNamespace={translatorForNamespace}
        />
      </StripeProvider>
    );
  }
}

export default withBookingProcessController(
  withErrorManagementOnTripsDeletion(withStripeScript(CreditCardPaymentPage))
);
