import check from "check-types";
import autoBind from "auto-bind";
import withSettingsWidgetProps from "../../components/containers/pages/shared/withSettingsWidgetProps";

export default class UserSettingsSdk {
  constructor(sdk) {
    this.sdk = sdk;
    autoBind(this);
  }

  getUserSavedCard() {
    const {
      userReadable: { cards },
    } = this.sdk.getAppState();

    let card = null;
    if (check.nonEmptyObject(cards)) {
      [card] = Object.values(cards);
    }

    return card;
  }

  withSettingsWidgetProps(...args) {
    return withSettingsWidgetProps(...args);
  }
}
