import React, { Component } from "react";
import check from "check-types";
import firebase from "firebase";
import sha1 from "sha1";
import { firebaseGet } from "@seafront/firebase-api-client";
import { format as formatEmail } from "@seafront/types/dist/email";
import { functionsEndpoint } from "../../../../config/firebase.json";

const UPDATE_PERIOD = 5000; // 5 secs

const withUpdatedOrders = WrappedComponent =>
  class WithUpdatedOrders extends Component {
    // Refresh anonymous orders on mount and every UPDATE_PERIOD ms
    async componentDidMount() {
      console.log("Mounting <WithUpdatedOrders>");
      this.interval = setInterval(() => {
        console.log("In interval");
        this.updateLoop().catch(console.error);
      }, UPDATE_PERIOD);

      await this.updateLoop();
    }

    // Stop polling on unmount
    componentWillUnmount() {
      console.log("Unmounting <WithUpdatedOrders>");
      try {
        clearInterval(this.interval);
        this.interval = null;
      } catch (err) {
        console.error(err);
      }
    }

    async updateLoop() {
      console.log("<WithUpdatedOrders>.updateLoop()");

      const {
        sdk,
        appState: {
          local: { loggedIn },
          userReadable: { ordersById }
        },
        setAnonymousUserReadableIfAppropriate = () => {}
      } = this.props;

      const { email } = sdk.billingDetails.getBillingDetails() || {};

      // Don't do nothing if we're logged in or if we don't have email
      if (loggedIn || !check.nonEmptyString(email)) {
        console.log(
          "  👐 Updating anonymous orders... Dropping because loggedIn or email is not a string : ",
          { loggedIn, email }
        );
        return;
      }

      // Don't do nothing if we don't have any pending order
      const pendingOrders = Object.values(ordersById || {}).filter(
        o =>
          o &&
          o.statusDetails &&
          !!o.statusDetails.paid &&
          !o.statusDetails.booked &&
          !o.statusDetails.cancelled &&
          !o.statusDetails.refunded &&
          !o.statusDetails.confirmed
      );
      if (!check.nonEmptyArray(pendingOrders)) {
        console.log(
          "  👐 Updating anonymous orders... Not updating because no pending order available"
        );
        return;
      }

      // If we're anonymous, update all pending orders
      console.log("🔄 Updating anonymous orders...");
      const updatedOrders = await firebaseGet(
        firebase,
        `https://${functionsEndpoint}/api/orders/batch/${pendingOrders
          .map(o => o.id)
          .join(",")}`,
        { auth: sha1(formatEmail(email)) }
      );
      if (updatedOrders && check.nonEmptyObject(updatedOrders.ordersById)) {
        await setAnonymousUserReadableIfAppropriate({
          ordersById: { ...(ordersById || {}), ...updatedOrders.ordersById }
        });
      } else {
        console.error(
          "❌ Could not update anonymous orders. Response = ",
          updatedOrders
        );
      }
    }

    render() {
      return <WrappedComponent {...this.props} />;
    }
  };

export default withUpdatedOrders;
