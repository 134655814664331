import React, { PureComponent } from "react";
import check from "check-types";
import arrayUnique from "array-unique";
import arrayFlatten from "array-flatten";

// FIXME: Use SeatsSdk whenever possible

const withSeatSelectorProps = (WrappedComponent) =>
  class WithSeatSelectorProps extends PureComponent {
    constructor(...args) {
      super(...args);
      this.state = {
        seatsPendingHold: [],
        seatsPendingRelease: [],
        error: null,
        selectedFloor: 0,
        // areSeatsPendingValidation: false,
      };

      // Bindings
      this.onSeatToggle = this.onSeatToggle.bind(this);
      this.onFloorChange = this.onFloorChange.bind(this);
      this.setManualSeatSelection = this.setManualSeatSelection.bind(this);
    }

    async componentDidMount() {
      // Push seats to server on mount to make sure we're in sync
      const { sdk, isReturn } = this.props;
      await sdk.seats.syncSeatsToServer(isReturn);
    }

    async componentWillUnmount() {
      // Push seats to server on mount to make sure we're in sync
      const { sdk, isReturn } = this.props;
      await sdk.seats.syncSeatsToServer(isReturn);
    }

    async setState(state, callback = () => {}) {
      return new Promise((resolve) => {
        super.setState(state, (...args) => {
          callback(...args);
          resolve(...args);
        });
      });
    }

    onFloorChange(floorNumber) {
      this.setState({ selectedFloor: floorNumber });
    }

    /*
    seatHasChangesPending(seat) {
      const { seatsPendingHold = [], seatsPendingRelease = [] } = this.state;
      return (
        seatsPendingHold.includes(seat) || seatsPendingRelease.includes(seat)
      );
    }

    async addSeatToPendingList(seat, isHold = false) {
      const { seatsPendingHold = [], seatsPendingRelease = [] } = this.state;
      await this.setState({
        seatsPendingHold: !isHold
          ? seatsPendingHold
          : arrayUnique([...(seatsPendingHold || []), seat]),
        seatsPendingRelease: isHold
          ? seatsPendingRelease
          : arrayUnique([...(seatsPendingRelease || []), seat]),
      });
    }

    async removeSeatFromPendingList(seat) {
      const { seatsPendingHold = [], seatsPendingRelease = [] } = this.state;
      await this.setState({
        seatsPendingHold: (seatsPendingHold || []).filter((s) => s !== seat),
        seatsPendingRelease: (seatsPendingRelease || []).filter(
          (s) => s !== seat
        ),
      });
    }
    */

    async onSeatHold(seat) {
      const { sdk, isReturn } = this.props;
      // await this.setState({ areSeatsPendingValidation: true });
      await sdk.seats.addSelectedSeats(isReturn, [seat]);
      await sdk.seats.holdSeatsOnServer(isReturn, [seat]);
    }

    async onSeatRelease(seat) {
      const { sdk, isReturn } = this.props;
      // await this.setState({ areSeatsPendingValidation: true });
      await sdk.seats.removeSelectedSeats(isReturn, [seat]);
      await sdk.seats.releaseSeatsOnServer(isReturn, [seat]);
    }

    async onSeatToggle(seat) {
      const { sdk, isReturn } = this.props;
      const selectedSeats = sdk.seats.getSelectedSeats(isReturn);
      if (selectedSeats.includes(seat)) {
        await this.onSeatRelease(seat);
      } else {
        await this.onSeatHold(seat);
      }
    }

    getSeatMatrix() {
      const { sdk, isReturn } = this.props;
      return sdk.seats.getSeatMatrix(isReturn);
    }

    getSimplifiedSeatMatrix() {
      const seatMatrix = this.getSeatMatrix();
      const nFloors = this.getNumberOfFloors();

      if (nFloors > 1) {
        console.log("Seat matrix as multiple floors!");
        const { selectedFloor } = this.state;
        return seatMatrix[selectedFloor];
      }

      return seatMatrix;
    }

    getNumberOfFloors() {
      const seatMatrix = this.getSeatMatrix();
      const hasMultipleFloors =
        check.nonEmptyArray(seatMatrix) &&
        check.nonEmptyArray(seatMatrix[0]) &&
        check.nonEmptyArray(seatMatrix[0][0]);

      if (!hasMultipleFloors) {
        return 1;
      }

      return seatMatrix.length;
    }

    async setManualSeatSelection(data) {
      const { sdk,  isReturn = false } = this.props;
      return sdk.seats.setManualSeatSelection(isReturn, data)
    }

    render() {
      const { sdk, selectedTrip = null, isReturn = false } = this.props;
      const {
        seatsPendingHold,
        seatsPendingRelease,
        selectedFloor,
        error,
        // areSeatsPendingValidation,
      } = this.state;

      const seatMatrix = this.getSimplifiedSeatMatrix();
      const numberOfFloors = this.getNumberOfFloors();

      // FIXME: Impement in SDK instead
      const seatPrices = seatMatrix === 'manual' ? [] : arrayUnique(
        arrayFlatten(seatMatrix)
          .filter((s) => s.exists && s.number)
          .map((s) => s.priceInCents || null)
          .filter((p) => !!p)
      ).sort();

      // FIXME: Impement in SDK instead
      const seatInfoByPrice = (
        priceInCents,
        {
          seatMatrixDefaultFreeColor,
          seatMatrixDefaultSelectedColor,
          seatMatrixAlternateColors,
        }
      ) => {
        const defaultFree = seatMatrixDefaultFreeColor;
        const defaultSelected = seatMatrixDefaultSelectedColor;

        const defaultResponse = {
          freeColor: defaultFree,
          selectedColor: defaultSelected,
          priceInCents,
          priceDelta: 0,
        };

        if (
          !check.integer(priceInCents) ||
          !check.nonEmptyArray(seatPrices) ||
          seatPrices[0] === priceInCents
        ) {
          return defaultResponse;
        }

        const basePrice = seatPrices[0];
        const priceIndex = seatPrices.indexOf(priceInCents);
        if (priceIndex < 0) {
          /*
          console.error(
            `Problem in seatLegendByPrice --> provided price is not in seat map: ${priceInCents}`
          );
          */
          return defaultResponse;
        }
        const colors =
          seatMatrixAlternateColors[
            (priceIndex - 1) % seatMatrixAlternateColors.length
          ];

        return {
          ...colors,
          priceInCents,
          priceDelta: priceInCents - basePrice,
        };
      };

      const numberOfSeatsToSelect = sdk.seats.getNumbersOfSeatsToSelect();
      const selectedSeats = sdk.seats.getSelectedSeats(isReturn);
      const seatsLeft = numberOfSeatsToSelect - selectedSeats.length;

      console.log("Rendering: withSeatsSelectorProps");

      return (
        <>
          <WrappedComponent
            {...{
              ...this.props,
              error,
              selectedTrip,
              seatMatrix,
              selectedSeats,
              seatsLeft,
              seatPrices,
              seatInfoByPrice,
              seatsPendingHold,
              seatsPendingRelease,
              loading: !check.nonEmptyArray(seatMatrix),
              areSeatsPendingValidation: false, // sdk.seats.isRequestToServerPending(),
              isReturn,
              onSeatToggle: this.onSeatToggle,
              numberOfSeatsToSelect,
              numberOfFloors,
              selectedFloor,
              onFloorChange: this.onFloorChange,
              selectedSeatsWithErrors: sdk.seats.selectedSeatsWithErrors(
                isReturn
              ),
              setManualSeatSelection: this.setManualSeatSelection
            }}
          />
        </>
      );
    }
  };

export default withSeatSelectorProps;
