import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import request from "request-promise";
import check from "check-types";
import firebase from "firebase";
import appendQuery from "append-query";
import queryString from "query-string";
import BuseaApi from "@seafront/busea.api";
import { ProcessingPaymentPage, LoadingScreen } from "../../../lib/theme";
import {
  translationManager,
  translatorForNamespace
} from "../../../lib/TranslationManager";
import stripeConfig from "../../../config/stripe.json";
import { functionsEndpoint } from "../../../config/firebase.json";
import withErrorManagementOnTripsDeletion from "./shared/withErrorManagementOnTripsDeletion";
import withBookingProcessController from "./shared/withBookingProcessController";
import { loadStripe } from "@stripe/stripe-js";

const stripePromise = loadStripe(stripeConfig.apiKey);

const PREV_PAGE = "/book/checkout";

const t = translatorForNamespace("StripeCheckoutPage");

class StripeCheckoutPage extends Component {
  constructor(...args) {
    super(...args);
    this.state = {
      error: null,
      success: false,
      confirmedOrderId: null,
      status: null
    };
  }

  async componentDidMount() {
    const { history } = this.props;

    const { status = null } = queryString.parse(window.location.search);
    await this.setState({ status });

    // Stripe redirects to this page with a status query param
    // at the end of the Checkout flow
    if (status === "payment-ok") {
      await this.bookCart();
    } else if (status === "cancelled") {
      history.push(PREV_PAGE);
    } else {
      // No status? It's our first visit here. We create a cart.
      await this.createCartAndRedirectToPaymentPage();
    }
  }

  async createCartAndRedirectToPaymentPage() {
    const {
      sdk,
      appState: {
        session: {
          sessionId = null,
          orderId = null,
          outboundTrip = null,
          returnTrip = null
        },
        local: { loggedIn },
        userReadable: { ordersById }
      },
      setSession = () => {},
      setAnonymousUserReadableIfAppropriate = () => {}
    } = this.props;

    const { email } = sdk.billingDetails.getBillingDetails() || {};
    const selectedPassengers = sdk.passengers.getPassengersSelectedForNextTrip();

    // Activate loading page
    await this.setState({
      error: null,
      success: false
    });

    const billingDetails = sdk.billingDetails.getBillingDetails() || {};

    // Process order
    const body = {
      // If we already created an order in this session,
      // communicate it to the server
      orderId: orderId || null,
      lang: translationManager.getCurrentLanguage(),
      sessionId,
      outboundRideId: outboundTrip.id,
      outboundSeats: sdk.seats.getSelectedSeats(false),
      returnRideId: returnTrip ? returnTrip.id : null,
      returnSeats: sdk.seats.getSelectedSeats(true),
      billingDetails,
      covid19Info: sdk.covid19.getStringifiedInfo(),
      passengerDetails: selectedPassengers,
      email,
      amount: Math.round(
        BuseaApi.getOrderPricing(
          outboundTrip,
          returnTrip,
          selectedPassengers.length
        ).total * 100
      ),
      successUrl: appendQuery(window.location.href, { status: "payment-ok" }),
      cancelledUrl: appendQuery(window.location.href, { status: "cancelled" })
    };

    const headers = {};
    if (loggedIn && firebase.auth().currentUser) {
      const authToken = await firebase.auth().currentUser.getIdToken(true);
      headers.Authorization = `Bearer ${authToken}`;
    }

    try {
      console.log("⚙️ Creating cart...");
      const response = await request({
        method: "post",
        uri: `https://${functionsEndpoint}/api/carts/`,
        json: true,
        headers,
        body
      });

      console.log("✅ Cart received OK: ", response);
      const cart = response;

      if (
        !check.nonEmptyObject(cart) ||
        !check.nonEmptyObject(cart.stripe) ||
        !check.nonEmptyString(cart.stripe.checkoutSessionId)
      ) {
        await this.setState({ error: new Error("Could not create cart") });
        return;
      }

      // Save cartId for after-payment
      await setSession({
        cartId: cart.id,
        orderId: cart.id,
        lastOrderId: cart.id
      });

      // If we're not logged in, keep order and card in userReadable until we log in
      await setAnonymousUserReadableIfAppropriate({
        ordersById: { ...(ordersById || {}), [cart.id]: cart }
      });

      // Redirect to Stripe Checkout page
      const stripe = await stripePromise;
      const result = await stripe.redirectToCheckout({
        sessionId: cart.stripe.checkoutSessionId
      });

      if (result.error) {
        console.error("Stripe error: " + result.error.message);
        await this.setState({ error: result.error });
        return;
      }
    } catch (error) {
      console.log(error);
      await this.setState({ error });
    }
  }

  async bookCart() {
    const {
      appState: {
        userReadable: { ordersById },
        session: { cartId = null },
        local: { loggedIn }
      },
      setSession = () => {},
      setAnonymousUserReadableIfAppropriate = () => {}
    } = this.props;

    if (!check.nonEmptyString(cartId)) {
      await this.setState({ error: new Error("CartId not found") });
      return;
    }

    try {
      const headers = {};
      if (loggedIn && firebase.auth().currentUser) {
        const authToken = await firebase.auth().currentUser.getIdToken(true);
        headers.Authorization = `Bearer ${authToken}`;
      }

      console.log("⚙️ Booking cart...");
      const response = await request({
        method: "post",
        uri: `https://${functionsEndpoint}/api/carts/${cartId}/book`,
        json: true,
        headers,
        body: {}
      });

      console.log("✅ Booking OK: ", response);

      // Save orderId for future use
      // In case we must re-attempt payment or booking after
      const order = response;
      await setSession({
        orderId: order.id,
        lastOrderId: order.id
      });

      // If we're not logged in, keep order and card in userReadable until we log in
      await setAnonymousUserReadableIfAppropriate({
        ordersById: { ...(ordersById || {}), [order.id]: order }
      });

      // Indicate success
      await this.setState({
        processing: false,
        success: true,
        confirmedOrderId: order.id
      });
    } catch (error) {
      console.log(error);
      await this.setState({ error });
    }
  }

  async setState(state) {
    return new Promise((resolve, reject) => {
      try {
        super.setState(state, resolve);
      } catch (err) {
        reject(err);
      }
    });
  }

  render() {
    const {
      error = null,
      success = false,
      confirmedOrderId = null
      // status = null
    } = this.state;

    if (error) {
      console.error("[StripeCheckout page] ERROR: ", error);
      /*
      return (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            height: "100%"
          }}
        >
          {error.message || error}
        </div>
      );
      */

      return <Redirect to={PREV_PAGE} />;
    }

    if (success) {
      return <Redirect to={`/book/in-progress/${confirmedOrderId}`} />;
    }

    return (
      <ProcessingPaymentPage translatorForNamespace={translatorForNamespace} />
    );
  }
}

export default withBookingProcessController(
  withErrorManagementOnTripsDeletion(StripeCheckoutPage)
);
