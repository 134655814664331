import React, { Component } from "react";
import withTicketFromUrl from "./shared/withTicketFromUrl";
import { GenericStopsPage as GenericStopsPageUI } from "../../../lib/theme";
import { translatorForNamespace } from "../../../lib/TranslationManager";

class TicketStopsPage extends Component {
  constructor(...args) {
    super(...args);

    this.state = {
      stops: null,
    };

    // Bindings
    this.onStopsUpdated = this.onStopsUpdated.bind(this);
  }

  async onStopsUpdated(snapshot) {
    this.setState({ stops: snapshot.val() || [] });
  }

  render() {
    const { ticket, onBack = () => {} } = this.props;
    const { stops } = this.state;

    return (
      <GenericStopsPageUI
        loading={stops === null}
        ticket={ticket}
        stops={stops}
        onBack={onBack}
        translatorForNamespace={translatorForNamespace}
      />
    );
  }
}

export default withTicketFromUrl(TicketStopsPage);
