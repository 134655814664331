import { Component } from 'react';

export default class OnlineListener extends Component {
  constructor(...args) {
    super(...args);

    this.updateStatus = this.updateStatus.bind(this);
  }

  componentDidMount() {
    this.startListening();
  }

  componentWillUnmount() {
    this.stopListening();
  }

  updateStatus() {
    const { onOnlineStatusChange = () => {} } = this.props;
    onOnlineStatusChange(window.navigator.onLine);
  }

  startListening() {
    window.addEventListener('online', this.updateStatus);
    window.addEventListener('offline', this.updateStatus);
  }

  stopListening() {
    window.removeEventListener('online', this.updateStatus);
    window.removeEventListener('offline', this.updateStatus);
  }

  render() {
    return null;
  }
}
