import React from "react";
import { EditPassengerPopup as EditPassengerPopupUI } from "../../../lib/theme";
import withSettingsWidgetProps from "./shared/withSettingsWidgetProps";

const EditPassengerPopup = (props) => {
  const {
    sdk,
    match: {
      params: { passengerId },
    },
    history,
  } = props;

  return (
    <EditPassengerPopupUI
      {...props}
      key={`edit_${passengerId}`}
      sdk={sdk}
      onDelete={async () => {
        await sdk.passengers.deletePassenger(passengerId);
        history.goBack(); // push("/settings/passengers");
      }}
      onEdit={async (p) => {
        await sdk.passengers.createOrEditPassenger({
          ...(p || {}),
          id: passengerId,
        });
        history.goBack();
      }}
      onBack={() => history.goBack() /* history.push("/settings/passengers") */}
      passenger={sdk.passengers.getPassengerData(passengerId) || {}}
    />
  );
};
export default withSettingsWidgetProps(EditPassengerPopup);
