import autoBind from "auto-bind";

export default class BillingDetailsSdk {
  constructor(sdk) {
    this.sdk = sdk;
    autoBind(this);
  }

  getBillingPassengerId() {
    // Check that saved passenger exists before returning id
    const d = this.getBillingDetails();
    return d ? d.id || null : null;
  }

  async setBillingPassengerId(billingDetailsId) {
    await this.sdk.setSession({ billingDetailsId });
  }

  getBillingDetails() {
    const {
      session: { billingDetailsId = null },
    } = this.sdk.getAppState();

    const data = this.sdk.passengers.getPassengerData(billingDetailsId);
    if (!data) {
      return null;
    }

    return {
      ...data,
      identityType: "passport", // FIXME: Change this
      identityNumber: data.identityNumber || data.ssn,
    };
  }

  async setBillingDetails(details) {
    const billingPassengerId = await this.sdk.passengers.createOrEditPassenger(
      details
    );
    await this.setBillingPassengerId(billingPassengerId);
  }

  areBillingDetailsValid() {
    return this.sdk.passengers.isBillingPassengerInfoValid(
      this.getBillingDetails()
    );
  }
}
