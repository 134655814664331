import React, { Component } from "react";
import autoBind from "auto-bind";
import * as firebaseui from "firebaseui";

import { Redirect } from "react-router-dom";
import firebase from "firebase";
import check from "check-types";
import queryString from "query-string";
import { withLastLocation } from "react-router-last-location";
import {
  LoginByPhoneFirstPage,
  LoginByPhoneSecondPage,
  LoginByPhoneThirdPage,
} from "../../../lib/theme";
import { translatorForNamespace } from "../../../lib/TranslationManager";

class LoginByPhonePage extends Component {
  constructor(...args) {
    super(...args);

    this.state = {
      loginOk: false,
      error: null,
      phone: null,
      code: null,
      loginPending: false,
      captchaOk: false,
      smsSent: false,
      confirmationResult: null,
      codeConfirmationPending: false,
    };

    // Bindings
    autoBind(this);
  }

  componentDidMount() {
    /*
    window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
      "phone-sign-in-button",
      {
        size: "invisible",
        callback: async (response) => {
          console.log("Captcha solved: ", response);
          // reCAPTCHA solved, allow signInWithPhoneNumber.
          await this.setState({ captchaOk: true });

          // Once captchaOk is set, reattempt sending sms if it wasn't
          // and we have the phone number
          await this.submitPhoneNumber();
        },
        "expired-callback": () => {
          console.log("expired-callback");
        },
      }
    );

    window.recaptchaVerifier.render().then(function (widgetId) {
      window.recaptchaWidgetId = widgetId;
    });
    */
    const ui = new firebaseui.auth.AuthUI(firebase.auth());
    ui.start("#firebaseui-auth-container", {
      signInOptions: [
        {
          provider: firebase.auth.PhoneAuthProvider.PROVIDER_ID,
          recaptchaParameters: {
            type: "image", // 'audio'
            size: "normal", // 'invisible' or 'compact'
            badge: "bottomleft", //' bottomright' or 'inline' applies to invisible.
          },
          defaultCountry: "EC", // Set default country to the United Kingdom (+44).
          // For prefilling the national number, set defaultNationNumber.
          // This will only be observed if only phone Auth provider is used since
          // for multiple providers, the NASCAR screen will always render first
          // with a 'sign in with phone number' button.
          defaultNationalNumber: "1234567890",
          // You can also pass the full phone number string instead of the
          // 'defaultCountry' and 'defaultNationalNumber'. However, in this case,
          // the first country ID that matches the country code will be used to
          // populate the country selector. So for countries that share the same
          // country code, the selected country may not be the expected one.
          // In that case, pass the 'defaultCountry' instead to ensure the exact
          // country is selected. The 'defaultCountry' and 'defaultNationaNumber'
          // will always have higher priority than 'loginHint' which will be ignored
          // in their favor. In this case, the default country will be 'GB' even
          // though 'loginHint' specified the country code as '+1'.
          loginHint: "+11234567890",
          // You can provide a 'whitelistedCountries' or 'blacklistedCountries' for
          // countries to select. It takes an array of either ISO (alpha-2) or
          // E164 (prefix with '+') formatted country codes. If 'defaultCountry' is
          // not whitelisted or is blacklisted, the default country will be set to the
          // first country available (alphabetical order). Notice that
          // 'whitelistedCountries' and 'blacklistedCountries' cannot be specified
          // at the same time.
        },
      ],
    });
  }

  async setState(state, callback = () => {}) {
    return new Promise((resolve) =>
      super.setState(state, (...args) => {
        callback(...args);
        resolve();
      })
    );
  }

  async submitPhoneNumber() {
    await this.setState({
      loginOk: false,
      loginPending: true,
      error: null,
      confirmationResult: null,
      codeConfirmationPending: false,
    });

    const { phone, smsSent, captchaOk } = this.state;

    // If captcha hasn't returned yet
    // or if sms has already been sent
    // or if phone number is invalid: do nothing
    if (!captchaOk || smsSent || !check.nonEmptyString(phone)) {
      console.error(
        "Cannot submit phone number: " +
          JSON.stringify({ captchaOk, smsSent, phone })
      );
      return;
    }

    // Else, submit phone number

    await this.setState({ smsSent: true });

    try {
      const appVerifier = window.recaptchaVerifier;
      console.log("Submitting phone number...");
      const confirmationResult = await firebase
        .auth()
        .signInWithPhoneNumber(phone, appVerifier);

      console.log("confirmationResult = ", confirmationResult);

      // SMS sent. Prompt user to type the code from the message, then sign the
      // user in with confirmationResult.confirm(code).
      await this.setState({
        loginPending: false,
        confirmationResult,
      });
    } catch (error) {
      console.error("ERROR submitting phone number: ", error);

      await this.setState({ loginPending: false, error, smsSent: false });
    }
  }

  async onPhoneNumberChange(phone) {
    console.log("onPhoneNumberChange(" + phone + ")");
    return this.setState({ phone });
  }

  async onConfirmationCodeChange(code) {
    await this.setState({ code });
    if (check.nonEmptyString(code) && code.length === 6) {
      await this.setState({ codeConfirmationPending: true });
      try {
        const { confirmationResult } = this.state;
        await confirmationResult.confirm(code);
        await this.setState({ codeConfirmationPending: false });
        await this.onLoginSuccess();
      } catch (error) {
        this.setState({ error });
      }
    }
  }

  async onEditPhone() {
    return this.setState({
      error: null,
      code: null,
      loginPending: false,
      smsSent: false,
      confirmationResult: null,
      codeConfirmationPending: false,
    });
  }

  async onLoginSuccess() {
    const { history, setSession = () => {} } = this.props;
    history.push(this.getNextPageLocation());
    await setSession({ isSigningIn: false });
  }

  getNextPageLocation() {
    const { next } = this.props;
    let location = null;

    if (next) {
      location = next;
    } else if (
      check.nonEmptyString(localStorage["busea.lastLocationBeforeLogin"])
    ) {
      location = localStorage["busea.lastLocationBeforeLogin"];
      localStorage["busea.lastLocationBeforeLogin"] = null;
    }

    if (check.nonEmptyString(location) && location.indexOf("/") === 0) {
      return location;
    }

    return "/";
  }

  goBack() {
    const { onBack = null, history } = this.props;
    let goBack = onBack || history.goBack;
    if (
      window &&
      window.location &&
      check.nonEmptyString(window.location.search)
    ) {
      const parsedQuery = queryString.parse(window.location.search);
      if (check.nonEmptyString(parsedQuery.back)) {
        goBack = () => history.push(parsedQuery.back);
      }
    }

    return goBack();
  }

  renderPhoneInputScreen() {
    const { error, phone, loginPending } = this.state;

    return (
      <LoginByPhoneFirstPage
        error={error}
        phone={phone}
        loading={loginPending}
        onPhoneChange={this.onPhoneNumberChange}
        onSubmit={() => {}}
        onBack={this.goBack}
        translatorForNamespace={translatorForNamespace}
      />
    );
  }

  renderCodeInputScreen() {
    const { error, phone, code, codeConfirmationPending } = this.state;

    return (
      <LoginByPhoneSecondPage
        error={error}
        phone={phone}
        code={code}
        loading={codeConfirmationPending}
        onCodeChange={this.onConfirmationCodeChange}
        onEditPhone={this.onEditPhone}
        onSubmit={this.onConfirmationCodeChange}
        onBack={this.goBack}
        translatorForNamespace={translatorForNamespace}
      />
    );
  }

  renderSuccessScreen() {
    const { history } = this.props;
    return (
      <LoginByPhoneThirdPage
        onNext={() => history.push(this.getNextPageLocation())}
        translatorForNamespace={translatorForNamespace}
      />
    );
  }

  renderRedirectPageAfterLogin() {
    return <Redirect to={this.getNextPageLocation()} />;
  }

  render() {
    const {
      appState: {
        local: { loggedIn = false },
      },
    } = this.props;

    const { loginOk, confirmationResult } = this.state;

    if (loggedIn) {
      return this.renderRedirectPageAfterLogin();
    }

    if (!confirmationResult) {
      return this.renderPhoneInputScreen();
    }

    if (!loginOk) {
      return this.renderCodeInputScreen();
    }

    return this.renderSuccessScreen();
  }
}

export default withLastLocation(LoginByPhonePage);
