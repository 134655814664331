import React, { Component } from "react";
import removeAccents from "remove-accents";
import PaperGlider from "paperglider";
import { BookingFormPage as BookingFormPageUI } from "../../../lib/theme.js";
import withBookingProcessController from "./shared/withBookingProcessController";
import {
  translationManager,
  translatorForNamespace,
} from "../../../lib/TranslationManager";

const dropDownSearch = (items, query) => {
  const q = removeAccents(query.trim()).toLowerCase();

  const results = items.filter((i) => i.text.toLowerCase().includes(q));
  results.sort((a, b) => {
    if (
      a.text.toLowerCase().indexOf(q) !== b.text.toLowerCase().indexOf(q) &&
      (a.text.toLowerCase().indexOf(q) === 0 ||
        b.text.toLowerCase().indexOf(q) === 0)
    ) {
      return a.text.toLowerCase().indexOf(q) - b.text.toLowerCase().indexOf(q);
    }
    return a.text.toLowerCase().localeCompare(b.text.toLowerCase());
  });

  return results;
};

class BookingFormPage extends Component {
  constructor(...args) {
    super(...args);

    // Bindings
    this.onFormChange = this.onFormChange.bind(this);
    this.onFormSubmit = this.onFormSubmit.bind(this);
  }

  async onFormSubmit(formResults) {
    const { sdk, history, isIdle = false } = this.props;
    const {
      selectedDeparture,
      selectedArrival,
      numberOfPassengers,
      outDate,
    } = formResults;

    await this.onFormChange(formResults);

    if (selectedDeparture && selectedArrival && numberOfPassengers && outDate) {
      // Clear previously selected reserved seats before submitting
      sdk.seats.clearAllSelectedSeats().catch(console.error);

      if (isIdle) {
        // If form is embedded in iFrame, send event when submitting
        // instead of changing URL
        try {
          PaperGlider.connect()
            .toParent()
            .request("idle-booking-form-validated", [], () => {});
        } catch (err) {
          console.error(
            "[Booking form] Could not send idle-form validation event: ",
            err
          );
        }
        console.log("[Booking form] Staying in place");
      } else {
        history.push("/book/out/");
        console.log("[Booking form] Moving to /book/out");
      }
    }
  }

  async onFormChange(formResults) {
    const { sdk } = this.props;
    await sdk.bookingForm.setBookingFormState(formResults);
  }

  render() {
    const {
      sdk,
      appState: {
        session: {
          selectedDeparture,
          selectedArrival,
          numberOfPassengers,
          outDate,
          returnDate,
          disableReturnField,
        },
      },
      isIdle = false,
    } = this.props;

    console.log("[BookingForm] Rendering...", {
      windowLocationHref: window.location.href,
      documentLocationHref: document.location.href,
      isIdle,
    });

    return (
      <BookingFormPageUI
        {...{
          formFields: {
            selectedDeparture,
            selectedArrival,
            numberOfPassengers,
            outDate,
            returnDate,
          },
          translationManager,
          translatorForNamespace,
          dropDownSearch,
          departures: sdk.bookingForm.departures(),
          arrivals: sdk.bookingForm.arrivalsForDeparture(selectedDeparture),
          minDepartureDay: sdk.bookingForm.minDepartureDay(),
          minReturnDay: sdk.bookingForm.minReturnDay(),
          maxBookingDay: sdk.bookingForm.maxBookingDay(),
          disableReturnField,
          isIdle,
        }}
        onChange={this.onFormChange}
        onSubmit={this.onFormSubmit}
      />
    );
  }
}

export default withBookingProcessController(BookingFormPage);
