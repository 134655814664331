import React from "react";
import { PassengerDetailsPage as PassengerDetailsPageUI } from "../../../lib/theme";
import withErrorManagementOnTripsDeletion from "./shared/withErrorManagementOnTripsDeletion";
import withBookingProcessController from "./shared/withBookingProcessController";

const PassengersDetailsPage = (props) => {
  const {
    sdk,
    appState: {
      session: { numberOfPassengers },
    },
    history,
    goBack = () => {},
    goToLastAllowedStep = () => {},
  } = props;

  return (
    <PassengerDetailsPageUI
      sdk={sdk}
      numberOfPassengers={numberOfPassengers}
      savedPassengers={sdk.passengers.getAllSavedPassengers()}
      selectedPassengers={sdk.passengers.getPassengersSelectedForNextTrip()}
      onPassengerToggle={(p) => sdk.passengers.togglePassengerForNextTrip(p)}
      onAddPassenger={() => history.push("/book/passenger-details/add")}
      onConfirm={goToLastAllowedStep}
      onBack={goBack}
      history={history}
    />
  );
};
export default withBookingProcessController(
  withErrorManagementOnTripsDeletion(PassengersDetailsPage)
);
