import React, { Component } from "react";
import firebase from "firebase";
import uniqid from "uniqid";
// import initRemoteConsoleSession from "@seafront/remote-console";
import * as Sentry from "@sentry/browser";
import isNative from "../../lib/is-native";
import sentryConfig from "../../config/sentry.json";
import Busea from "./Busea";

const DEBUG = false;

// Init Sentry
Sentry.init(sentryConfig);
Sentry.setTag("Source", "React");

const sendMessageToNativeWrapperIfAny = (messageType, data = {}) => {
  if (
    isNative() &&
    window &&
    window.ReactNativeWebView &&
    window.ReactNativeWebView.postMessage
  ) {
    window.ReactNativeWebView.postMessage(
      JSON.stringify({ type: messageType, ...(data || {}) })
    );
  }
};

const setLocalStorage = (key, value) => {
  localStorage[key] = value;
  sendMessageToNativeWrapperIfAny("storage", {
    key,
    value
  });
};

class BuseaAppWrapper extends Component {
  constructor(...args) {
    super(...args);

    // Bindings
    this.signIn = this.signIn.bind(this);
    this.nativeSignIn = this.nativeSignIn.bind(this);

    // If we're running on native,
    // add a hook to enable ReactNative to start us
    if (isNative()) {
      window.startBusea = () => {
        this.start();
      };

      window.buseaNativeHooks = {
        signIn: this.signIn
      };
    }

    // If we're not, we can start
    this.state = {
      canStart: isNative() ? window && window.buseaCanStart : false,
      signInError: null,
      isSigningIn: false,
      debugAlert: null
    };

    // Bindings
    this.startDebug = this.startDebug.bind(this);
    this.stopDebug = this.stopDebug.bind(this);
  }

  componentDidMount() {
    if (isNative()) {
      // Indicate to ReactNative that this wrapper is loaded
      window.ReactNativeWebView.postMessage(
        JSON.stringify({ type: "wrapper-loaded" })
      );
    } else {
      // Start Web version on mount to init remote console
      this.start();
    }
  }

  async setState(state, callback = () => {}) {
    return new Promise(resolve => {
      super.setState(state, (...args) => {
        callback(...args);
        resolve(...args);
      });
    });
  }

  debugAlert(msg) {
    this.setState({ debugAlert: msg });
    setTimeout(() => {
      this.setState({ debugAlert: null });
    }, 10000);
  }

  // Ask the provider to sign in
  nativeSignIn(provider, data = {}) {
    // Don't set isSigningIn if provider is "password" as state is managed
    // in the web app
    this.setState(
      { isSigningIn: provider !== "password", signInError: null },
      () => {
        window.ReactNativeWebView.postMessage(
          JSON.stringify({ type: "sign-in", provider, ...(data || {}) })
        );
      }
    );
  }

  async signIn(authResponse, callback = () => {}) {
    this.debugAlert(
      `BuseaAppWrapper.signIn() => ${JSON.stringify(authResponse, null, 4)}`
    );

    if (!authResponse) {
      this.debugAlert(
        "ERROR received from Native Auth (): No auth response received"
      );
      this.setState({
        signInError: "No auth response received",
        isSigningIn: false
      });
      return;
    }
    const {
      provider,
      success = false,
      credentials = null,
      error = null
    } = authResponse;

    if (!success || !credentials) {
      this.debugAlert(
        `ERROR received from Native Auth (${provider}): ${
          credentials ? error : "No credentials received"
        }`
      );

      this.setState({
        signInError: credentials ? error : "No credentials received",
        isSigningIn: false
      });
      return;
    }

    try {
      let fbCredentials = null;
      switch (provider) {
        case "facebook":
          fbCredentials = firebase.auth.FacebookAuthProvider.credential(
            credentials
          );
          await firebase.auth().signInWithCredential(fbCredentials);
          this.debugAlert("Facebook login OK");
          break;
        case "google":
          {
            const { accessToken, idToken } = credentials;
            fbCredentials = firebase.auth.GoogleAuthProvider.credential(
              idToken,
              accessToken
            );
            await firebase.auth().signInWithCredential(fbCredentials);
            this.debugAlert("Google login OK");
          }
          break;
        case "password":
          {
            const { email, password } = credentials;
            await firebase.auth().signInWithEmailAndPassword(email, password);
            this.debugAlert("Password login OK");
          }
          break;
        default:
          // Do nothing
          break;
      }

      this.setState({
        signInError: null,
        isSigningIn: false
      });
    } catch (err) {
      this.setState({
        signInError: err.message,
        isSigningIn: false
      });

      if (isNative()) {
        // Indicate to ReactNative that this wrapper is loaded
        window.ReactNativeWebView.postMessage(
          JSON.stringify({ type: "sign-in-error" })
        );
      }

      this.debugAlert(`ERROR received from signIn try loop: ${err.message}`);
    }

    callback();
  }

  async startDebug() {
    setLocalStorage("busea-remoteConsoleActive", "true");

    const remoteConsoleChannel =
      localStorage["busea-remoteConsoleChannel"] || `busea-${uniqid()}`;
    setLocalStorage("busea-remoteConsoleChannel", remoteConsoleChannel);

    // ⚠️ Disabled console.re as it causes HTTPS error on clients
    // await initRemoteConsoleSession(remoteConsoleChannel);
    await this.setState({ remoteConsoleChannel });

    return remoteConsoleChannel;
  }

  async stopDebug() {
    setLocalStorage("busea-remoteConsoleActive", "false");
    await this.setState({ remoteConsoleChannel: null });
  }

  isDebugOn() {
    return localStorage["busea-remoteConsoleActive"] === "true";
  }

  async start() {
    // Start remote logging session
    if (this.isDebugOn()) {
      await this.startDebug();
    }

    // Set canStart to true
    this.setState({ canStart: true });
  }

  render() {
    const {
      canStart,
      signInError,
      isSigningIn,
      debugAlert,
      remoteConsoleChannel
    } = this.state;
    if (!canStart) {
      return "";
    }

    return (
      <>
        {debugAlert && DEBUG && (
          <pre
            style={{
              position: "fixed",
              bottom: -10,
              left: 0,
              width: "100%",

              fontSize: "12px",
              backgroundColor: "black",
              color: "white"
            }}
          >
            {debugAlert}
          </pre>
        )}

        <Busea
          nativeSignIn={this.nativeSignIn}
          nativeSignInError={signInError}
          nativeSignInPending={isSigningIn}
          remoteConsoleChannel={remoteConsoleChannel}
          startDebug={this.startDebug}
          stopDebug={this.stopDebug}
        />
      </>
    );
  }
}

export default BuseaAppWrapper;
