import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import check from "check-types";
import PaperGlider from "paperglider";
import autoBind from "auto-bind";
import appConfig from "../../config/app.json";

class RouteBroadcaster extends Component {
  constructor(...args) {
    super(...args);

    this.communicationLink = null;
    this.unlisten = null;

    this.lastContentHeight = 0;
    this.contentHeightInterval = null;
    autoBind(this);
  }

  componentDidMount() {
    const { history } = this.props;

    // Only do something if broadcasting of routes is ON
    if (appConfig.broadcastRoutes !== true) {
      return;
    }

    try {
      this.communicationLink = PaperGlider.connect().toParent();
      this.unlisten = history.listen(this.onLocationChange);

      // Listen for clicks and periodically check content height to
      // detect and broadcast changes in the app content's height
      this.contentHeightInterval = setInterval(
        this.checkContentHeightAndBroadcastChanges,
        200
      );
      window.addEventListener(
        "click",
        this.checkContentHeightAndBroadcastChanges
      );
    } catch (err) {
      console.error(
        "[RouteBroadcaster] Could not create iframe communication link: ",
        err
      );
    }
  }

  componentWillUnmount() {
    // Only do something if broadcasting of routes is ON
    if (appConfig.broadcastRoutes !== true) {
      return;
    }

    if (check.function(this.unlisten)) {
      this.unlisten();
    }

    // Clear listeners for document height change (1/2)
    if (this.contentHeightInterval) {
      clearInterval(this.contentHeightInterval);
      this.contentHeightInterval = null;
    }

    // Clear listeners for document height change (2/2)
    try {
      window.removeEventListener(
        "click",
        this.checkContentHeightAndBroadcastChanges
      );
    } catch (err) {
      console.error(err);
    }
  }

  checkContentHeightAndBroadcastChanges() {
    // Only do something if broadcasting of routes is ON
    if (appConfig.broadcastRoutes !== true) {
      return;
    }
    const container =
      document.querySelector(".container") || window.document.body;
    const newContentHeight = container.scrollHeight;
    if (newContentHeight !== this.lastContentHeight) {
      this.lastContentHeight = newContentHeight;
      this.onDocumentHeightChange(newContentHeight);
    }
  }

  onDocumentHeightChange(newHeight) {
    console.log("📐 App dimensions changed. New height = " + newHeight + "px");
    if (this.communicationLink) {
      this.communicationLink.request("size-change", [newHeight], () => {
        /* We don't use the callback function */
      });
    }
  }

  onLocationChange(location, action) {
    // Only do something if broadcasting of routes is ON
    if (appConfig.broadcastRoutes !== true) {
      return;
    }

    console.log("[RouteBroadcaster].onLocationChange()", location);

    if (this.communicationLink) {
      const path = location.pathname + location.hash;
      console.log("On route change:" + path);
      this.communicationLink.request("route-change", [path], () => {
        /* We don't use the callback function */
      });
    } else {
      console.error("[RouteBroadcaster].onLocationChange() --> NO LINK UP");
    }
  }

  render() {
    const { children } = this.props;
    return <>{children}</>;
  }
}

export default withRouter(RouteBroadcaster);
