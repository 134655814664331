import React from "react";
import { Redirect } from "react-router-dom";
import check from "check-types";
// import BuseaApi from '@seafront/busea.api';
import { BookingErrorPage as BookingErrorPageUI } from "../../../lib/theme";
import withBookingProcessController from "./shared/withBookingProcessController";

import isNative from "../../../lib/is-native";
import { translatorForNamespace } from "../../../lib/TranslationManager";

const t = translatorForNamespace("BookingErrorPage");

// Displays different status text depending on situation
// TODO: Add timeout to order error page?
const BookingErrorPage = (props) => {
  const {
    sdk,
    timeout = false,
    appState: {
      userReadable: { cards, ordersById = {} },
    },
    match: {
      params: { orderId = null },
    },
    history,
  } = props;

  const order = ordersById[orderId];
  const error = order ? order.error : null;
  // const { outboundTrip, returnTrip, passengerDetails } = order;

  let icon = "server-error";
  let redirectToSuccess = false;
  let text = "";
  let resetSeatsAndRetry = false;
  let showMultiplePaymentOptions = false;

  // const orderPricing = BuseaApi.getOrderPricing(outboundTrip, returnTrip, passengerDetails.length);
  // const payAmount = orderPricing.total;

  let card = null;
  if (check.nonEmptyObject(cards)) {
    [card] = Object.values(cards);
  }

  if (timeout) {
    text = t(
      "Your order is taking more time than usual. Please check your network connection and retry."
    );
  } else {
    switch (error || "") {
      case "payment-error":
      case "server-error:payment":
        showMultiplePaymentOptions = true;
        text = (
          <div
            style={{
              textAlign: "left",
              width: "100%",
              maxWidth: "90vw",
              margin: "auto",
            }}
          >
            {t("Ooops...")}
            <br />
            {t("We had a problem billing your") + " "}
            <strong>{t("credit/debit card")}</strong>.
            <br />
            {t("Please check that")}
            <ul>
              <li>{t("your card can make online purchases")}</li>
              <li>
                {t(
                  "your card can make international purchases (the payment is processed in USA)"
                )}
              </li>
              <li>
                {t(
                  "you've got sufficient funds and have not reached any payment limit"
                )}
              </li>
            </ul>
            {t(
              "If you have another credit or debit card, you may want to try that one instead."
            )}
            <br />
            <br />
            {t("If the problem persists, please contact us on WhatsApp at") +
              " "}
            <a
              target={isNative() ? null : "_blank"}
              href={
                isNative()
                  ? window.location.href
                  : "https://api.whatsapp.com/send/?phone=+13476730458&text&app_absent=0"
              }
            >
              {"+1 347 673 0458"}
            </a>
            <br />
            {t("Thanks 😊")}
          </div>
        );
        icon = "payment-error";
        break;

      case "booking-error":
      case "server-error:booking":
        icon = "server-error";
        resetSeatsAndRetry = true;
        text = t(
          "There was an error booking your order. Your purchase was refunded. Trying to book other seats might help."
        );
        break;

      case "server-error:pending-order-notif":
      case "server-error:purchase-notif":
        redirectToSuccess = true;
        break;

      default:
        text = t(
          "There was an unexpected error processing your order. Your purchase was refunded. Please contact support if you keep seeing this error."
        );
        break;
    }
  }

  if (redirectToSuccess) {
    return <Redirect to="/book/booking-confirmed/" />;
  }

  return (
    <BookingErrorPageUI
      icon={icon}
      message={text}
      resetSeatsAndRetry={resetSeatsAndRetry}
      showMultiplePaymentOptions={showMultiplePaymentOptions}
      savedCard={card}
      // payAmount={payAmount}
      payWithSavedCardLink={cards ? "/book/checkout/pay/" : null}
      payWithNewCardLink={cards ? "/book/checkout/pay/new-card" : null}
      onResetSeatsAndRetry={async () => {
        await sdk.seats.clearAllSelectedSeats();
        history.push("/book/checkout/");
      }}
      onBack={() => history.push("/book/checkout/")}
      translatorForNamespace={translatorForNamespace}
    />
  );
};

export default withBookingProcessController(BookingErrorPage);
