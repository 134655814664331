import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import firebase from "firebase";
import check from "check-types";
import queryString from "query-string";
import { withLastLocation } from "react-router-last-location";
import isNative from "../../../../lib/is-native";
import {
  SignUpPage as SignUpPageUI,
  LoginPage as LoginPageUI,
  LoggingInPage as LoggingInPageUI,
} from "../../../../lib/theme";
import { translatorForNamespace } from "../../../../lib/TranslationManager";

class SignInOrSignUpPage extends Component {
  constructor(...args) {
    super(...args);

    this.state = {
      error: null,
      email: this.getCurrentAppEmail(),
      password: null,
    };

    // Bindings
    this.onEmailChange = this.onEmailChange.bind(this);
    this.onPasswordChange = this.onPasswordChange.bind(this);
    this.onLogin = this.onLogin.bind(this);
    this.onFacebookSignIn = this.onFacebookSignIn.bind(this);
    this.onGoogleSignIn = this.onGoogleSignIn.bind(this);
  }

  onEmailChange(email) {
    this.setState({ email: email.trim() });
  }

  onPasswordChange(password) {
    this.setState({ password });
  }

  async onLogin() {
    const { email, password } = this.state;
    const {
      next,
      history,
      setSession = () => {},
      nativeSignIn = () => {},
    } = this.props;

    await setSession({ isSigningIn: true });
    await this.setState({ error: null });

    try {
      await this.signInOrSignUp(email, password);
      if (next) {
        history.push(next);
      } else {
        const location = localStorage["busea.lastLocationBeforeLogin"];
        localStorage["busea.lastLocationBeforeLogin"] = null;
        console.log(
          "Signed in! busea.lastLocationBeforeLogin = ",
          JSON.stringify(location)
        );
        if (check.nonEmptyString(location) && location.indexOf("/") === 0) {
          history.push(location);
        } else {
          history.push("/");
        }
      }

      // On ReactNative, simply save the credentials when we're sure they're good
      if (isNative()) {
        nativeSignIn("password", { email, password });
        await setSession({ isSigningIn: false });
      }
    } catch (err) {
      await this.setState({ error: err.message });
      await setSession({ isSigningIn: false });
    }
  }

  async onFacebookSignIn() {
    // Save last location to return to it once logged in
    const {
      /* lastLocation, */ setSession = () => {},
      nativeSignIn = () => {},
    } = this.props;
    /* if (lastLocation && check.nonEmptyString(lastLocation.pathname)) {
      localStorage['busea.lastLocationBeforeLogin'] = lastLocation.pathname;
    } */

    if (!isNative()) {
      await setSession({ isSigningIn: true });
      // On web, redirect
      const provider = new firebase.auth.FacebookAuthProvider();
      provider.addScope("email");
      // provider.addScope('user_birthday');
      firebase.auth().signInWithRedirect(provider);
    } else {
      // On ReactNative, call wrapper to login via Facebook Mobile SDK
      nativeSignIn("facebook");
      await setSession({ isSigningIn: false });
    }
  }

  async onGoogleSignIn() {
    // Save last location to return to it once logged in
    const {
      /* lastLocation, */ setSession = () => {},
      nativeSignIn = () => {},
    } = this.props;
    /*
    if (lastLocation && check.nonEmptyString(lastLocation.pathname)) {
      localStorage['busea.lastLocationBeforeLogin'] = lastLocation.pathname;
    }
    */

    if (!isNative()) {
      await setSession({ isSigningIn: true });
      // On web, redirect
      const provider = new firebase.auth.GoogleAuthProvider();
      // provider.addScope('https://www.googleapis.com/auth/contacts.readonly');
      firebase.auth().signInWithRedirect(provider);
    } else {
      // On ReactNative, call wrapper to login via Facebook Mobile SDK
      nativeSignIn("google");
      await setSession({ isSigningIn: false });
    }
  }

  async onAppleSignIn() {
    const { setSession = () => {}, nativeSignIn = () => {} } = this.props;

    if (!isNative()) {
      await setSession({ isSigningIn: true });
      // On web, redirect
      const provider = new firebase.auth.GoogleAuthProvider();
      firebase.auth().signInWithRedirect(provider);
    } else {
      // On ReactNative, call wrapper to login via Facebook Mobile SDK
      nativeSignIn("apple");
      await setSession({ isSigningIn: false });
    }
  }

  getCurrentAppEmail() {
    const { appState } = this.props;
    let email;
    if (appState.user && check.nonEmptyString(appState.user.email)) {
      ({ email } = appState.user);
    }

    return email;
  }

  async setState(state) {
    return new Promise((resolve) => super.setState(state, resolve));
  }

  async signIn(email, password) {
    return firebase.auth().signInWithEmailAndPassword(email, password);
  }

  async signInOrSignUp(email, password) {
    try {
      await this.signIn(email, password);
    } catch (err) {
      if (err.code === "auth/user-not-found") {
        await firebase.auth().createUserWithEmailAndPassword(email, password);
      }
      throw err;
    }
  }

  render() {
    const { error = null, email, password } = this.state;
    const {
      type,
      next,
      appState: {
        local: { loggedIn = false },
        session: { isSigningIn = false },
      },
      // lastLocation,
      history,
      onBack = null,
      nativeSignInError = null,
      nativeSignInPending = false,
    } = this.props;
    const UIComponent = type === "login" ? LoginPageUI : SignUpPageUI;

    // Logged in ?
    if (loggedIn) {
      // Redirect to the next location
      let location = null;

      if (next) {
        location = next;
      } else if (
        check.nonEmptyString(localStorage["busea.lastLocationBeforeLogin"])
      ) {
        location = localStorage["busea.lastLocationBeforeLogin"];
        localStorage["busea.lastLocationBeforeLogin"] = null;
      }
      console.log(
        "Signed in! busea.lastLocationBeforeLogin = ",
        JSON.stringify(location)
      );

      if (check.nonEmptyString(location) && location.indexOf("/") === 0) {
        return <Redirect to={location} />;
      }

      return <Redirect to="/" />;
    }

    if (isSigningIn || nativeSignInPending) {
      return (
        <LoggingInPageUI translatorForNamespace={translatorForNamespace} />
      );
    }

    let goBack = onBack || history.goBack;
    if (
      window &&
      window.location &&
      check.nonEmptyString(window.location.search)
    ) {
      const parsedQuery = queryString.parse(window.location.search);
      if (check.nonEmptyString(parsedQuery.back)) {
        goBack = () => history.push(parsedQuery.back);
      }
    }

    return (
      <UIComponent
        error={isNative() ? error || nativeSignInError : error}
        email={email}
        password={password}
        onEmailChange={this.onEmailChange}
        onPasswordChange={this.onPasswordChange}
        onSubmit={this.onLogin}
        onFacebookSignIn={this.onFacebookSignIn}
        onGoogleSignIn={this.onGoogleSignIn}
        onBack={goBack}
        translatorForNamespace={translatorForNamespace}
      />
    );
  }
}

export default withLastLocation(SignInOrSignUpPage);
