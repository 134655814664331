import check from "check-types";
import moment from "moment";
import objectPick from "object.pick";

const MIN_MINUTES_BEFORE_DEPARTURE = 60;
const MIN_MINUTES_IN_RIDE_DURATION = 15;

const dateInferiorTo = (d1, d2) =>
  moment(d1, "YYYY-MM-DD").valueOf() < moment(d2, "YYYY-MM-DD");

export default class BookingFormSdk {
  constructor(sdk) {
    this.sdk = sdk;
  }

  // Booking form
  minBookingDate() {
    return moment().add(MIN_MINUTES_BEFORE_DEPARTURE, "minutes");
  }

  maxBookingDate() {
    return moment().add(1, "months");
  }

  maxBookingDay() {
    return this.maxBookingDate().format("YYYY-MM-DD");
  }

  minDepartureDay() {
    return this.minBookingDate().format("YYYY-MM-DD");
  }

  minReturnDay(newOutDate = null) {
    const {
      session: { outDate },
    } = this.sdk.getAppState();

    const o = newOutDate || outDate;

    const res = check.nonEmptyString(o) ? o : this.minDepartureDay();
    return res;
  }

  departures() {
    const { cache: { routes = {}, locations = {} } = {} } =
      this.sdk.getAppState() || {};

    const departuresKeys = Object.keys(routes);
    departuresKeys.sort();

    const departures = departuresKeys
      .map((id) => {
        if (!locations[id]) {
          console.warn(`Location '${id}' doesn't exist`);
          return null;
        }
        return {
          key: `dep_${id}`,
          value: id,
          text: locations[id].displayName,
        };
      })
      .filter((d) => !!d);

    return departures;
  }

  arrivalsForDeparture(selectedDeparture) {
    const { cache: { routes = {}, locations = {} } = {} } =
      this.sdk.getAppState() || {};

    let arrivalKeys = Object.keys(locations);
    if (selectedDeparture) {
      arrivalKeys = routes[selectedDeparture]
        ? Object.values(routes[selectedDeparture])
        : [];
    }
    arrivalKeys.sort();

    const arrivals = arrivalKeys
      .map((id) => {
        if (!locations[id]) {
          console.warn(`Location '${id}' doesn't exist`);
          return null;
        }
        return {
          key: `arr_${id}`,
          value: id,
          text: locations[id].displayName,
        };
      })
      .filter((a) => !!a);

    return arrivals;
  }

  async setBookingFormState(newState = {}) {
    const { session } = this.sdk.getAppState();
    const { setSession = () => {} } = this.sdk;

    // Get state of booking form
    let {
      selectedDeparture,
      selectedArrival,
      numberOfPassengers,
      outDate,
      returnDate,
    } = session;
    const state = {
      selectedDeparture,
      selectedArrival,
      numberOfPassengers,
      outDate,
      returnDate,
      disableReturnField: false,
      ...newState,
    };

    // ////////////
    // Validate fields
    // ////////////

    // Reset destination if departure-->destination is not a route
    if (
      check.nonEmptyString(state.selectedDeparture) &&
      check.nonEmptyString(state.selectedArrival)
    ) {
      const arrivalsForDeparture = this.arrivalsForDeparture(
        state.selectedDeparture
      );
      if (
        !arrivalsForDeparture
          .map((a) => a.value)
          .includes(state.selectedArrival)
      ) {
        state.selectedArrival = null;
      }
    }

    // Disable return fields if destination-->departure is not a route
    if (
      check.nonEmptyString(state.selectedDeparture) &&
      check.nonEmptyString(state.selectedArrival)
    ) {
      const arrivalsForDeparture = this.arrivalsForDeparture(
        state.selectedArrival
      );
      if (
        !arrivalsForDeparture
          .map((a) => a.value)
          .includes(state.selectedDeparture)
      ) {
        state.disableReturnField = true;
        state.returnDate = null;
      }
    }

    // Change outbound date if outbound date is not in range
    if (
      check.nonEmptyString(state.outDate) &&
      dateInferiorTo(state.outDate, this.minDepartureDay())
    ) {
      state.outDate = this.minDepartureDay();
    } else if (
      check.nonEmptyString(state.outDate) &&
      dateInferiorTo(this.maxBookingDay(), state.outDate)
    ) {
      state.outDate = this.maxBookingDay();
    }

    // Change return date if return date is not in range
    if (check.nonEmptyString(state.returnDate)) {
      if (dateInferiorTo(state.returnDate, this.minReturnDay(state.outDate))) {
        state.returnDate = this.minReturnDay(state.outDate);
      } else if (dateInferiorTo(this.maxBookingDay(), state.returnDate)) {
        state.returnDate = this.maxBookingDay();
      }
    }

    // Number of passengers can't be < 1
    if (!state.numberOfPassengers || state.numberOfPassengers < 1) {
      state.numberOfPassengers = 1;
    }

    // ////////////
    // Update other session data on fields change
    // ////////////

    // Clear passenger details if we change the number of passengers
    if (session.numberOfPassengers !== state.numberOfPassengers) {
      await setSession({ passengerDetails: [] });
    }

    // Clear outboundTrip if we change departure city or departure date
    if (
      session.selectedDeparture !== state.selectedDeparture ||
      session.outDate !== state.outDate
    ) {
      await setSession({ outboundTrip: null });
    }

    // Clear returnTrip if we change arrival city or return date
    if (
      session.selectedArrival !== state.selectedArrival ||
      session.returnDate !== state.returnDate
    ) {
      await setSession({ returnTrip: null });
    }

    // ////////////
    // Save results in session
    // ////////////

    // Save results in session
    await setSession(
      objectPick(state, [
        "selectedDeparture",
        "selectedArrival",
        "numberOfPassengers",
        "outDate",
        "returnDate",
        "disableReturnField",
      ])
    );
  }

  // Rides list
  _getRides(
    departureTerminalId,
    arrivalTerminalId,
    day,
    numberOfPassengers = 1
  ) {
    const {
      cache: { rides: ridesCache = {} },
    } = this.sdk.getAppState();

    if (!departureTerminalId || !arrivalTerminalId || !day) {
      return [];
    }

    const path = `${departureTerminalId}/${arrivalTerminalId}/${day}`;
    let rides = ridesCache[path] || null;

    if (check.nonEmptyArray(rides)) {
      // Sort rides by departure time
      rides.sort((a, b) => {
        const da = moment(a.departureTime);
        const db = moment(b.departureTime);

        return da.valueOf() - db.valueOf();
      });

      // Filter out rides with departure before minimum booking date
      // as cache from server may contain past rides
      rides = rides.filter(
        (r) =>
          moment(r.departureTime).valueOf() > this.minBookingDate().valueOf()
      );

      // Filter rides with an estimated arrival less than 15min after departure
      // FIXME: Should be done on server?
      rides = rides.filter(
        (r) =>
          moment(r.departureTime)
            .add(MIN_MINUTES_IN_RIDE_DURATION, "minutes")
            .valueOf() < moment(r.arrivalTime).valueOf()
      );

      // Filter out rides with less seats available than the number of passengers required
      rides = rides.filter(
        (r) =>
          check.integer(r.availableSeats) &&
          r.availableSeats >= numberOfPassengers
      );
    }

    return rides;
  }

  getOutboundRides() {
    const {
      session: {
        selectedDeparture,
        selectedArrival,
        outDate,
        numberOfPassengers,
      },
    } = this.sdk.getAppState();

    if (
      !check.nonEmptyString(selectedDeparture) ||
      !check.nonEmptyString(selectedArrival) ||
      !check.nonEmptyString(outDate)
    ) {
      return [];
    }

    return this._getRides(
      selectedDeparture,
      selectedArrival,
      outDate,
      numberOfPassengers
    );
  }

  getReturnRides() {
    const {
      session: {
        selectedDeparture,
        selectedArrival,
        returnDate,
        outboundTrip,
        numberOfPassengers,
      },
    } = this.sdk.getAppState();

    if (
      !check.nonEmptyString(selectedDeparture) ||
      !check.nonEmptyString(selectedArrival) ||
      !check.nonEmptyString(returnDate)
    ) {
      return [];
    }

    let rides = this._getRides(
      selectedArrival,
      selectedDeparture,
      returnDate,
      numberOfPassengers
    );

    // Filter out rides before arrival of outbound trip if any
    if (outboundTrip && outboundTrip.arrivalTime) {
      rides = rides.filter(
        (r) =>
          moment(outboundTrip.arrivalTime).valueOf() <
          moment(r.departureTime)
            .subtract(MIN_MINUTES_IN_RIDE_DURATION, "minutes")
            .valueOf()
      );
    }

    return rides;
  }
}
