import React from "react";
import withErrorManagementOnTripsDeletion from "./withErrorManagementOnTripsDeletion";
import withSeatSelectorProps from "./withSeatSelectorProps";
import withBookingProcessController from "../shared/withBookingProcessController";
import { GenericTripSeatSelectionPage as GenericTripSeatSelectionPageUI } from "../../../../lib/theme";

const GenericTripSeatSelectionPage = (props) => {
  console.log("Props for GenericTripSeatSelectionPage: ", props);
  const { sdk } = props;
  return (
    <GenericTripSeatSelectionPageUI
      {...props}
      isOneWayTrip={!sdk.bookingProcess.isCurrentOrderARoundTrip()}
    />
  );
};

export default withBookingProcessController(
  withErrorManagementOnTripsDeletion(GenericTripSeatSelectionPage)
);
