import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { LoadingScreen } from "../../../lib/theme.js";
import { translatorForNamespace } from "../../../lib/TranslationManager";

const t = translatorForNamespace("LogOutPage");

class LogoutPage extends Component {
  constructor(...args) {
    super(...args);
    this.state = {
      done: false,
    };
  }

  async componentDidMount() {
    const { logOut = () => {} } = this.props;

    await logOut();
    this.setState({ done: true });
  }

  render() {
    const {
      match: {
        params: { nextPath },
      },
    } = this.props;
    const { done } = this.state;

    if (!done) {
      return <LoadingScreen text={t("Logging out")} />;
    }

    return <Redirect to={nextPath || "/"} />;
  }
}

export default LogoutPage;
