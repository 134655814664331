import React from "react";
import { DateSelectionPopup as DateSelectionPopupUI } from "../../../lib/theme";
import {
  translationManager,
  translatorForNamespace,
} from "../../../lib/TranslationManager";
import withBookingProcessController from "./shared/withBookingProcessController";

const t = translatorForNamespace("ReturnDatePopup");

const ReturnDatePopup = (props) => {
  const {
    sdk,
    appState: {
      session: { returnDate = null },
    },
    history,
  } = props;

  return (
    <DateSelectionPopupUI
      title={t("Return date")}
      date={returnDate}
      minDate={sdk.bookingForm.minReturnDay()}
      maxDate={sdk.bookingForm.maxBookingDay()}
      onChange={async (returnDate) => {
        await sdk.bookingForm.setBookingFormState({ returnDate });
        history.goBack(); // Using history to keep compatibility with /book/idle-form
      }}
      onBack={history.goBack}
      {...{ translationManager, translatorForNamespace }}
    />
  );
};
export default withBookingProcessController(ReturnDatePopup);
