import React from "react";
import GenericTripSeatSelectionPage from "./shared/GenericTripSeatSelectionPage";

const OutTripSeatSelectionPage = (props) => {
  const {
    sdk,
    appState,
    appState: {
      session: {
        sessionId,
        selectedDeparture,
        selectedArrival,
        outDate,
        returnDate,
        outboundTrip,
        numberOfPassengers,
      },
    },
    history,
    setSession,
  } = props;

  return (
    <GenericTripSeatSelectionPage
      sdk={sdk}
      appState={appState}
      sessionId={sessionId}
      origin={selectedDeparture}
      destination={selectedArrival}
      date={outDate}
      selectedTrip={outboundTrip}
      isReturn={false}
      numberOfSeatsToSelect={numberOfPassengers}
      setTrip={async (trip) => {
        await setSession({ outboundTrip: trip });
      }}
      onBack={() => {
        console.log("Calling onBack from OutTripSeatSelectionPage");
        history.goBack();
      }}
      //  Props below used by GenericTripDetails page
      isOneWayTrip={!returnDate}
      numberOfPassengers={numberOfPassengers}
      selectedPassengers={sdk.passengers.getPassengersSelectedForNextTrip()}
      showSeatsLink="/book/out/select/seats/"
      showStopsLink="/book/out/select/stops/"
      confirmLink="/book/return/"
      history={history}
    />
  );
};

export default OutTripSeatSelectionPage;
