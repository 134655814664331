import React from "react";
import GenericTripsListPage from "./shared/GenericTripsListPage";

const OutTripsListPage = (props) => {
  const {
    sdk,
    appState: {
      session: {
        selectedDeparture,
        selectedArrival,
        outDate,
        outboundTrip,
        numberOfPassengers,
      },
      cache: { rides = {} },
    },
    setSession = () => {},
    history,
  } = props;

  return (
    <GenericTripsListPage
      sdk={sdk}
      appState={props.appState}
      origin={selectedDeparture}
      destination={selectedArrival}
      date={outDate}
      ridesCache={rides}
      isReturn={false}
      selectedTrip={outboundTrip}
      onTripSelect={async (trip) => {
        await setSession({ outboundTrip: trip });
        console.log("outboundTrip trip set to: ", trip);
        history.push("/book/out/select/");
      }}
      history={history}
      numberOfPassengers={numberOfPassengers}
    />
  );
};

export default OutTripsListPage;
