import check from "check-types";

export default class TypesSdk {
  constructor(sdk) {
    this.sdk = sdk;
  }

  isAddressObjectValid(o) {
    return this.validateAddressObject(o).isValid;
  }

  validateAddressObject(o) {
    let isValid = true;
    const errors = [];

    if (!check.nonEmptyObject(o)) {
      isValid = false;
      errors.push("<root>");
      return { isValid, errors };
    }

    if (
      !check.nonEmptyString(o.line1) ||
      !check.nonEmptyString(o.line1.trim())
    ) {
      isValid = false;
      errors.push("line1");
    }

    if (!check.nonEmptyString(o.city) || !check.nonEmptyString(o.city.trim())) {
      isValid = false;
      errors.push("city");
    }

    // Don't require zip as some people in Ecuador don't have one
    /*
    if (!check.nonEmptyString(o.zip) || !check.nonEmptyString(o.zip.trim())) {
      isValid = false;
      errors.push("zip");
    }
    */

    if (
      !check.nonEmptyString(o.country) ||
      !check.nonEmptyString(o.country.trim())
    ) {
      isValid = false;
      errors.push("country");
    }

    return {
      isValid,
      errors,
    };
  }

  addressToString(address) {
    const {
      line1 = "",
      line2 = "",
      city = "",
      zip = "",
      country = "",
    } = address;

    if (!check.nonEmptyString(line1) || !check.nonEmptyString(city)) {
      return "";
    }

    return (
      line1 +
      (line2 ? ", " : "" + line2) +
      ", " +
      (check.nonEmptyString(zip) ? zip + " " : "") +
      city +
      ", " +
      (check.nonEmptyString(country) ? country.toUpperCase() : "EC")
    );
  }
}
