import { Component } from "react";
import firebase from "firebase";
import check from "check-types";
import filterProps from "filter-properties";
import shallowObjectEquals from "object-equal";
import { encode } from "firebase-encode";

export default class ExpandedRideListener extends Component {
  constructor(...args) {
    super(...args);

    this.daemon = null;
    this.currentRidePath = null;

    // Bindings
    this.onRidesUpdate = this.onRidesUpdate.bind(this);
  }

  componentDidMount() {
    this.startListening();
  }

  componentDidUpdate(prevProps) {
    const whitelist = ["rideId", "origin", "destination", "date"];

    if (
      !shallowObjectEquals(
        filterProps(whitelist, this.props),
        filterProps(whitelist, prevProps)
      )
    ) {
      this.stopListening();
      this.startListening();
    }
  }

  componentWillUnmount() {
    this.stopListening();
  }

  onRidesUpdate(snapshot) {
    const {
      origin,
      destination,
      date,
      rideId,
      onExpandedRideDataUpdate = () => {},
    } = this.props;

    let data = {};
    const val = snapshot.val();
    if (val && val.lastRefresh) {
      data = val.data || null;
    }

    onExpandedRideDataUpdate(origin, destination, date, rideId, data);
  }

  startListening() {
    const { origin, destination, date, rideId } = this.props;

    if (this.daemon || this.currentRidePath) {
      return;
    }
    if (!origin || !destination || !date || !rideId) {
      return;
    }

    // Start listening to DB updates
    this.currentRidePath = `/public/expandedRidesData/${encode(rideId)}`;
    firebase
      .database()
      .ref(this.currentRidePath)
      .on("value", this.onRidesUpdate);

    // Start daemon to trigger server periodically
    const trigger = () => {
      const triggerPath = `/public/triggers/expand-rides/${encode(rideId)}`;
      firebase.database().ref(triggerPath).set(Date.now());
    };
    trigger();
    // FIXME: Re-enable update daemon?
    this.daemon = setInterval(trigger, 2 * 60 * 1000);
  }

  stopListening() {
    if (this.daemon) {
      clearInterval(this.daemon);
      this.daemon = null;
    }
    if (this.currentRidePath) {
      firebase
        .database()
        .ref(this.currentRidePath)
        .off("value", this.onRidesUpdate);
      this.currentRidePath = null;
    }
  }

  render() {
    return null;
  }
}
