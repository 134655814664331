import React, { Component } from "react";
import uniqid from "uniqid";
import moment from "../../../lib/moment.js";
import { BookingConfirmedPage as BookingConfirmedPageUI } from "../../../lib/theme";
import { translatorForNamespace } from "../../../lib/TranslationManager";
import withBookingProcessController from "./shared/withBookingProcessController";

class BookingConfirmed extends Component {
  // If booking is confirmed, reset booking session
  async componentDidMount() {
    const { sdk, setSession = () => {} } = this.props;

    await setSession({
      sessionId: uniqid(),
      orderId: null,
      selectedDeparture: null,
      selectedArrival: null,
      numberOfPassengers: 1,
      outDate: moment().format("YYYY-MM-DD"),
      returnDate: null,
      passengerDetails: null,
      outboundTrip: null,
      returnTrip: null,
      covidInfo: null,
      billingDetailsId: null,
      returnTripSeats: null,
      outboundTripSeats: null,
      outboundSeatsErrors: null,
      returnSeatsErrors: null,
    });

    await sdk.seats.clearAllSelectedSeats();
  }

  render() {
    const {
      appState: {
        local: { loggedIn },
        userReadable: { ordersById },
        session: { lastOrderId },
      },
    } = this.props;

    const lastOrder = ordersById[lastOrderId];
    console.log("Last order: ", lastOrder);

    return (
      <BookingConfirmedPageUI
        pending={!lastOrder.statusDetails.booked}
        createAccountLink="/create-account/"
        viewTicketsLink={`/orders/${lastOrderId}`}
        {...{ /* outboundTrip, returnTrip, */ loggedIn }}
        translatorForNamespace={translatorForNamespace}
      />
    );
  }
}
export default /* withBookingProcessController */ BookingConfirmed;
