import React from "react";
import { PassengerDetailsAddPage as AddPassengerPopupUI } from "../../../lib/theme";
import withErrorManagementOnTripsDeletion from "./shared/withErrorManagementOnTripsDeletion";
import withBookingProcessController from "./shared/withBookingProcessController";

const AddPassengerPopup = (props) => {
  const { sdk, history, self = false } = props;

  const onAdd = async (p) => {
    if (!sdk.passengers.isBasicPassengerInfoValid(p)) {
      console.log("Aborting add as basic info is not here");
      return;
    }

    // Create user
    await sdk.passengers.createPassengerSelectIt(p, self);

    // Go back
    history.push("/book/passenger-details/");
  };

  return (
    <AddPassengerPopupUI
      {...props}
      sdk={sdk}
      onAdd={onAdd}
      onBack={() => history.push("/book/passenger-details/")}
    />
  );
};

export default withBookingProcessController(
  withErrorManagementOnTripsDeletion(AddPassengerPopup)
);
