import check from "check-types";
import arrayUnique from "array-unique";
import uniqid from "uniqid";
import isEmailValid from "@seafront/is-email-valid";
import autoBind from "auto-bind";

export default class PassengersSdk {
  constructor(sdk) {
    this.sdk = sdk;

    // Bindings
    autoBind(this);
  }

  emptyPassenger() {
    return {
      firstName: "",
      lastName: "",
      ssn: "",
      country: null,
      phone: "",
      email: "",
      address: {
        line1: "",
        line2: "",
        city: "",
        zip: "",
        country: "",
      },
    };
  }

  getAllSavedPassengers() {
    const {
      user: { savedPassengers = {} },
    } = this.sdk.getAppState();

    if (!check.nonEmptyObject(savedPassengers)) {
      return [];
    }
    return this.sortPassengersByName(
      Object.values(savedPassengers)
        .map((p) => this.getPassengerData(p.id))
        .filter((p) => !!p)
    );
  }

  getAllSavedPassengersIds() {
    return arrayUnique(this.getAllSavedPassengers().map((p) => p.id));
  }

  getPassengerData(id) {
    const {
      user: { savedPassengers = {}, passengerId },
    } = this.sdk.getAppState();

    if (
      !check.nonEmptyString(id) ||
      !check.nonEmptyObject(savedPassengers) ||
      !check.nonEmptyObject(savedPassengers[id])
    ) {
      return null;
    }

    return { ...savedPassengers[id], isUser: id === passengerId };
  }

  getUserPassengerData() {
    const {
      user: { passengerId },
    } = this.sdk.getAppState();

    return this.getPassengerData(passengerId);
  }

  getUserPassengerId() {
    const data = this.getUserPassengerData();
    if (!data || !check.nonEmptyString(data.id)) {
      return null;
    }
    return data.id;
  }

  getPassengersSelectedForNextTrip() {
    const {
      session: { passengerDetails = [] },
    } = this.sdk.getAppState();

    if (!check.nonEmptyArray(passengerDetails)) {
      return [];
    }

    return this.sortPassengersByName(
      arrayUnique(
        passengerDetails.map((i) => (check.nonEmptyObject(i) ? i.id : i))
      )
        .map((id) => this.getPassengerData(id))
        .filter((p) => !!p)
    );
  }

  getPassengersIdsSelectedForNextTrip() {
    return this.getPassengersSelectedForNextTrip().map((p) => p.id);
  }

  async selectPassengerForNextTrip(p) {
    const id = this._passengerIdFromPassengerOrId(p);
    if (!!id) {
      await this.sdk.setSession({
        passengerDetails: arrayUnique([
          id,
          ...this.getPassengersIdsSelectedForNextTrip(),
        ]),
      });
    }
  }

  async deselectPassengerForNextTrip(p) {
    const id = this._passengerIdFromPassengerOrId(p);
    if (!!id) {
      await this.sdk.setSession({
        passengerDetails: this.getPassengersIdsSelectedForNextTrip().filter(
          (i) => i !== id
        ),
      });
    }
  }

  async togglePassengerForNextTrip(p) {
    const id = this._passengerIdFromPassengerOrId(p);
    if (!id) {
      return;
    }

    if (this.getPassengersIdsSelectedForNextTrip().includes(id)) {
      await this.deselectPassengerForNextTrip(id);
    } else {
      await this.selectPassengerForNextTrip(id);
    }
  }

  isBasicPassengerInfoValid(p) {
    return this.validateBasicPassengerInfo(p).isValid;
  }

  validateBasicPassengerInfo(p) {
    let isValid = true;
    const errors = [];

    if (!check.nonEmptyObject(p)) {
      isValid = false;
      errors.push("<root>");

      return { isValid, errors };
    }

    /*
    // Not checking for ID as this is generated by our functions
    if (!check.nonEmptyString(p.id)) {
      isValid = false;

      errors.push("id");
    }
    */

    if (
      !check.nonEmptyString(p.firstName) ||
      !check.nonEmptyString(p.firstName.trim())
    ) {
      isValid = false;
      errors.push("firstName");
    }

    if (
      !check.nonEmptyString(p.lastName) ||
      !check.nonEmptyString(p.lastName.trim())
    ) {
      isValid = false;
      errors.push("lastName");
    }

    const countryValid =
      check.nonEmptyString(p.country) && check.nonEmptyString(p.country.trim());
    if (!countryValid) {
      isValid = false;
      errors.push("country");
    }

    const ssnValid =
      check.nonEmptyString(p.ssn) && check.nonEmptyString(p.ssn.trim());
    if (!ssnValid) {
      isValid = false;
      errors.push("ssn");
    }

    if (
      ssnValid &&
      countryValid &&
      !this.basicCedulaValidation(p.ssn, p.country)
    ) {
      isValid = false;
      errors.push("cedula");
    }

    return {
      isValid,
      errors,
    };
  }

  isBillingPassengerInfoValid(p) {
    return this.validateBillingPassengerInfo(p).isValid;
  }

  validateBillingPassengerInfo(p) {
    const basicValidation = this.validateBasicPassengerInfo(p);

    let { isValid } = basicValidation;
    const { errors } = basicValidation;

    if (errors.includes("<root>")) {
      return { isValid, errors };
    }

    if (!check.nonEmptyString(p.email) || !isEmailValid(p.email)) {
      isValid = false;
      errors.push("email");
    }

    if (
      !check.nonEmptyString(p.phone) ||
      !check.nonEmptyString(p.phone.trim())
    ) {
      isValid = false;
      errors.push("phone");
    }

    if (!check.nonEmptyObject(p.address)) {
      isValid = false;
      errors.push("address");
      return { isValid, errors };
    }

    if (
      !check.nonEmptyString(p.address.line1) ||
      !check.nonEmptyString(p.address.line1.trim())
    ) {
      isValid = false;
      errors.push("address.line1");
    }

    if (
      !check.nonEmptyString(p.address.city) ||
      !check.nonEmptyString(p.address.city.trim())
    ) {
      isValid = false;
      errors.push("address.city");
    }

    // Don't require zip as some people in Ecuador don't have one
    /*
    if (
      !check.nonEmptyString(p.address.zip) ||
      !check.nonEmptyString(p.address.zip.trim())
    ) {
      isValid = false;
      errors.push("address.zip");
    }
    */

    if (
      !check.nonEmptyString(p.address.country) ||
      !check.nonEmptyString(p.address.country.trim())
    ) {
      isValid = false;
      errors.push("address.country");
    }

    return {
      isValid,
      errors,
    };
  }

  async basicCedulaValidation(ssn, country) {
    if (!check.nonEmptyString(ssn) || !check.nonEmptyString(country)) {
      return false;
    }

    if (country === "ec") {
      return /^[0-9]{10}$/.test(ssn);
    }

    return /^[0-9A-Z-]+$/.test(ssn);
  }

  generateNewPassengerId() {
    const id = "passenger_" + uniqid();
    if (this.getAllSavedPassengersIds().includes(id)) {
      return this.generateNewPassengerId();
    }
    return id;
  }

  async createOrEditPassenger(p) {
    if (check.nonEmptyObject(p) && !check.nonEmptyString(p.id)) {
      p.id = this.generateNewPassengerId();
    }

    if (!this.isBasicPassengerInfoValid(p)) {
      throw new Error(
        "Trying to save passenger with invalid info: " + JSON.stringify(p)
      );
    }

    const {
      user: { savedPassengers = {}, passengerId },
    } = this.sdk.getAppState();

    const editedPassenger = { ...p, isUser: p.id === passengerId };

    // Save it and select it
    await this.sdk.setUserState({
      savedPassengers: { ...savedPassengers, [p.id]: editedPassenger },
    });

    return p.id;
  }

  async deletePassenger(p) {
    const {
      user: { savedPassengers = {}, passengerId },
    } = this.sdk.getAppState();

    const id = this._passengerIdFromPassengerOrId(p);
    if (!id) {
      return;
    }

    console.log("Asked to delete passenger with id: ", id);
    const newSavedPassengers = {};
    const savedPassengersValues = Object.values(savedPassengers || {});
    savedPassengersValues.forEach((sp) => {
      if (sp.id !== id) {
        newSavedPassengers[sp.id] = sp;
      }
    });

    console.log("New passengers: ", newSavedPassengers);

    await this.sdk.setUserState({
      savedPassengers: newSavedPassengers,
      passengerId: id === passengerId ? null : passengerId,
    });

    await this.sdk.setSession({
      passengerDetails: this.getPassengersIdsSelectedForNextTrip().filter(
        (s) => s.id !== id
      ),
    });
  }

  async assignPassengerToUser(p) {
    const {
      user: { email },
    } = this.sdk.getAppState();

    const id = this._passengerIdFromPassengerOrId(p);
    if (!id) {
      return;
    }

    await this.sdk.setUserState({
      passengerId: id,
      email: p.email && isEmailValid(p.email) ? p.email : email,
    });
  }

  async createPassengerSelectIt(p, assignToUser = false) {
    const id = await this.createOrEditPassenger(p);
    await this.selectPassengerForNextTrip(id);
    if (assignToUser) {
      await this.assignPassengerToUser(id);
    }

    return id;
  }

  sortPassengersByName(passengers) {
    if (!check.nonEmptyArray(passengers)) {
      return [];
    }

    return passengers.sort((a, b) => (a.firstName > b.firstName ? 1 : -1));
  }

  allPassengersSelectedAndValid() {
    const {
      session: { numberOfPassengers },
    } = this.sdk.getAppState();

    const selectedAndValid = this.getPassengersSelectedForNextTrip().filter(
      (p) => this.isBasicPassengerInfoValid(p)
    );
    return selectedAndValid.length === numberOfPassengers;
  }

  _passengerIdFromPassengerOrId(p) {
    const id = check.nonEmptyObject(p) ? p.id : p;
    const data = this.getPassengerData(id);

    if (data) {
      return id;
    }

    return null;
  }
}
