import { Component } from "react";
import firebase from "firebase";
import filterProps from "filter-properties";
import shallowObjectEquals from "object-equal";
import check from "check-types";

export default class RidesListener extends Component {
  constructor(...args) {
    super(...args);

    this.daemon = null;
    this.currentRidePath = null;

    // Bindings
    this.onRidesUpdate = this.onRidesUpdate.bind(this);
  }

  componentDidMount() {
    this.startListening();
  }

  componentDidUpdate(prevProps) {
    const whitelist = ["origin", "destination", "date"];

    if (
      !shallowObjectEquals(
        filterProps(whitelist, this.props),
        filterProps(whitelist, prevProps)
      )
    ) {
      this.stopListening();
      this.startListening();
    }
  }

  componentWillUnmount() {
    this.stopListening();
  }

  onRidesUpdate(snapshot) {
    const { origin, destination, date, onRidesUpdate = () => {} } = this.props;

    let data = null;
    const val = snapshot.val();
    if (val && val.lastRefresh) {
      if (check.nonEmptyObject(val.data)) {
        data = Object.values(val.data);
      } else if (!check.nonEmptyArray(val.data)) {
        data = [];

        // If loading isn't finished, keep data to null until results array is filled up
        if (!val.loadingFinished) {
          data = null;
        }
      }
    } else {
      data = null;
    }

    onRidesUpdate(origin, destination, date, data);
  }

  startListening() {
    const { origin, destination, date } = this.props;

    if (this.daemon || this.currentRidePath) {
      return;
    }
    if (!origin || !destination || !date) {
      return;
    }

    const rideBasePath = `/rides/${origin}/${destination}/${date}/null`;
    this.currentRidePath = `/public${rideBasePath}`;

    firebase
      .database()
      .ref(this.currentRidePath)
      .on("value", this.onRidesUpdate);

    // Start daemon to trigger server periodically
    const trigger = () => {
      const triggerPath = `/public/triggers${rideBasePath}`;
      firebase
        .database()
        .ref(triggerPath)
        .set(Date.now());
    };
    trigger();
    // FIXME: Re-enable update daemon?
    this.daemon = setInterval(trigger, 3 * 60 * 1000);
  }

  stopListening() {
    if (this.daemon) {
      clearInterval(this.daemon);
      this.daemon = null;
    }
    if (this.currentRidePath) {
      firebase
        .database()
        .ref(this.currentRidePath)
        .off("value", this.onRidesUpdate);
      this.currentRidePath = null;
    }
  }

  render() {
    return null;
  }
}
