import check from "check-types";
import formatNumber from "format-number";

const formatPrice = (price) =>
  formatNumber({ prefix: "$", round: 2, padRight: 2 })(price);

export default class TerminalsSdk {
  constructor(sdk) {
    this.sdk = sdk;
  }

  humanPricingForRoute(route, numberOfPassengers = 1, split = true) {
    if (split) {
      if (numberOfPassengers <= 1) {
        return formatPrice(route.grossPrice);
      }

      return numberOfPassengers + " x " + formatPrice(route.grossPrice);
    }

    return formatPrice(numberOfPassengers * route.grossPrice);
  }
}
