import React from "react";
import { GenericTripsListPage as GenericTripsListPageUI } from "../../../../lib/theme";
import withBookingProcessController from "../shared/withBookingProcessController";

const GenericTripListPage = ({
  sdk,
  origin,
  destination,
  date,
  selectedTrip = null,
  ridesCache,
  returnDate,
  isReturn = false,
  numberOfPassengers,
  onTripSelect = () => {},
  goBack = () => {},
  goForward = () => {},
  history,
}) => {
  return (
    <GenericTripsListPageUI
      {...{
        sdk,
        origin,
        destination,
        date,
        selectedTrip,
        rides: isReturn
          ? sdk.bookingForm.getReturnRides()
          : sdk.bookingForm.getOutboundRides(),
        isReturn,
        onTripSelect,
        onBack: goBack,
        numberOfPassengers,
        isOneWayTrip: !sdk.bookingProcess.isCurrentOrderARoundTrip(),
      }}
    />
  );
};

export default withBookingProcessController(GenericTripListPage);
