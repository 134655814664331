import React from "react";
import { findTickets } from "./lib/ordersUtils";
import withUpdatedOrders from "./shared/withUpdatedOrders";
import {
  OrdersPage as OrdersPageUI,
  EmptyOrdersPage as EmptyOrdersPageUI,
} from "../../../lib/theme";
import {
  translationManager,
  translatorForNamespace,
} from "../../../lib/TranslationManager";

const OrdersPage = (props) => {
  const {
    appState: {
      userReadable: { ordersById = {} },
    },
    match: {
      params: { orderId = null },
    },
    history,
  } = props;

  const tickets = findTickets(ordersById, { orderId });

  if (tickets.length === 0) {
    return (
      <EmptyOrdersPageUI translatorForNamespace={translatorForNamespace} />
    );
  }

  return (
    <OrdersPageUI
      tickets={tickets}
      isFiltered={!!orderId && Object.keys(ordersById).length > 1}
      onUnfilter={() => {
        history.push("/orders/");
      }}
      translationManager={translationManager}
      translatorForNamespace={translatorForNamespace}
    />
  );
};
export default withUpdatedOrders(OrdersPage);
