import React from "react";
import { Redirect } from "react-router-dom";
import GenericTripSeatSelectionPage from "./shared/GenericTripSeatSelectionPage";

const ReturnTripSeatSelectionPage = (props) => {
  const {
    sdk,
    appState,
    appState: {
      session: {
        sessionId,
        selectedDeparture,
        selectedArrival,
        returnDate,
        returnTrip,
        numberOfPassengers,
      },
    },
    history,
    setSession,
  } = props;

  if (!returnDate) {
    return <Redirect to="/book/return/select/" />;
  }

  return (
    <GenericTripSeatSelectionPage
      sdk={sdk}
      appState={appState}
      sessionId={sessionId}
      origin={selectedArrival}
      destination={selectedDeparture}
      date={returnDate}
      selectedTrip={returnTrip}
      isReturn={true}
      numberOfSeatsToSelect={numberOfPassengers}
      setTrip={async (trip) => {
        await setSession({ returnTrip: trip });
      }}
      onBack={() => history.goBack()}
      //  Props below used by GenericTripDetails page
      isOneWayTrip={!returnDate}
      numberOfPassengers={numberOfPassengers}
      selectedPassengers={sdk.passengers.getPassengersSelectedForNextTrip()}
      showSeatsLink="/book/return/select/seats/"
      showStopsLink="/book/return/select/stops/"
      confirmLink="/book/checkout/"
      history={history}
    />
  );
};

export default ReturnTripSeatSelectionPage;
