import React from "react";
import withTicketFromUrl from "./shared/withTicketFromUrl";
import {
  TicketPage,
  EmptyOrdersPage as EmptyOrdersPageUI,
} from "../../../lib/theme";
import { findTickets } from "./lib/ordersUtils";
import withUpdatedOrders from "./shared/withUpdatedOrders";
import {
  translationManager,
  translatorForNamespace,
} from "../../../lib/TranslationManager";

const TicketDetailsPage = ({
  appState: {
    userReadable: { ordersById = {} },
  },
  match: {
    params: { orderId = null },
  },
  ticket,
  history,
}) => {
  // FIXME: Put this part in SDK
  const tickets = findTickets(ordersById, { orderId });

  if (tickets.length === 0) {
    return (
      <EmptyOrdersPageUI translatorForNamespace={translatorForNamespace} />
    );
  }
  // END OF FIXME: Put this part in SDK

  return (
    <TicketPage
      {...{
        ticket,
        onBack: () => {
          history.push("/orders/");
        },
      }}
      tickets={tickets}
      translationManager={translationManager}
      translatorForNamespace={translatorForNamespace}
    />
  );
};
export default withUpdatedOrders(withTicketFromUrl(TicketDetailsPage));
