import React from "react";
import { Redirect } from "react-router-dom";
import GenericTripsListPage from "./shared/GenericTripsListPage";
import withErrorManagementOnTripsDeletion from "./shared/withErrorManagementOnTripsDeletion";

const ReturnTripsListPage = (props) => {
  const {
    sdk,
    appState: {
      session: {
        selectedDeparture,
        selectedArrival,
        returnDate,
        returnTrip,
        numberOfPassengers,
      },
      cache: { rides = {} },
    },
    setSession = () => {},
    history,
  } = props;

  if (!returnDate) {
    return <Redirect to="/book/checkout/" />;
  }

  return (
    <GenericTripsListPage
      sdk={sdk}
      appState={props.appState}
      origin={selectedArrival}
      destination={selectedDeparture}
      date={returnDate}
      ridesCache={rides}
      isReturn
      selectedTrip={returnTrip}
      onTripSelect={async (trip) => {
        await setSession({ returnTrip: trip });
        console.log("Return trip set to: ", trip);
        history.push("/book/return/select/");
      }}
      history={history}
      numberOfPassengers={numberOfPassengers}
    />
  );
};

export default withErrorManagementOnTripsDeletion(ReturnTripsListPage);
