import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import queryString from "query-string";
import check from "check-types";
// import SignInOrSignUpPage from '../pages/shared/SignInOrSignUpPage';

import { translatorForNamespace } from "../../../lib/TranslationManager";
import {
  BetaOnboardingCarousselPage,
  BetaOnboardingWaitingPage,
} from "../../../lib/theme";
import step1Pic from "./assets/step1.svg";
import step2Pic from "./assets/step2.svg";
import step3Pic from "./assets/step3.svg";
import step4Pic from "./assets/step4.svg";

const ONBOARDING_STEPS = [
  {
    picture: step1Pic,
    title: "Bienvenidos a Busea!",
    text:
      "La aplicación que revolucionara la manera que compras tus boletos de bus en el Ecuador.",
  },
  {
    picture: step2Pic,
    title: "Compra tus boletos de Bus desde tu teléfono!",
    text:
      "Olvidate de desplazarte al Terminal o hacer fila. Viaja por el Ecuador sin perder tiempo.",
  },
  {
    picture: step3Pic,
    title: "Toda la informacion en un solo lugar.",
    text:
      "Compara rutas, horarios, frecuencias, precios y Cooperativas de Transporte para llegar a tu destino deseado.",
  },
  {
    picture: step4Pic,
    title: "Pago en linea 100% seguro",
    text:
      "Paga tu boleto de bus en nuestra App con tu tarjeta de débito o crédito de cualquier banco. Accede a tus pasajes desde tu teléfono.",
    footer: "Comienza a probarla desde ahora!",
  },
];

export default class BetaOnboarding extends Component {
  constructor(...args) {
    super(...args);

    let step = 0;

    if (
      window &&
      window.location &&
      check.nonEmptyString(window.location.search)
    ) {
      const parsedQuery = queryString.parse(window.location.search);
      if (parsedQuery.step) {
        step = parseInt(parsedQuery.step, 10) || 0;
      }
    }

    this.state = {
      step,
    };
  }

  render() {
    const {
      appState: {
        local: { loggedIn = false },
      },
      logOut = async () => {},
    } = this.props;
    const { step } = this.state;

    const {
      next = null,
      byPassWaitingList = false,
      onNext = () => {},
    } = this.props;

    if (!byPassWaitingList && loggedIn) {
      return (
        <BetaOnboardingWaitingPage
          onLogout={logOut}
          translatorForNamespace={translatorForNamespace}
        />
      );
    }

    console.log(`step = ${step}`);
    console.log(`ONBOARDING_STEPS.length = ${ONBOARDING_STEPS.length}`);

    if (step < ONBOARDING_STEPS.length) {
      return (
        <BetaOnboardingCarousselPage
          translatorForNamespace={translatorForNamespace}
          selectedStep={step}
          steps={ONBOARDING_STEPS}
          prevButtonDisabled={step === 0}
          nextButtonLabel={
            step === ONBOARDING_STEPS.length - 1 ? "Book a ticket" : "Next" // "Sign up" : "Next"
          }
          onPrev={() => this.setState({ step: Math.max(step - 1, 0) })}
          onNext={() => {
            const nextStep = Math.min(step + 1, ONBOARDING_STEPS.length);
            if (nextStep >= ONBOARDING_STEPS.length) {
              onNext();
            }
            this.setState({ step: nextStep });
          }}
        />
      );
    }

    return (
      /*
      <SignInOrSignUpPage
        type="signUp"
        {...this.props}
        onBack={() => this.setState({ step: ONBOARDING_STEPS.length - 1 })}
      />
      */
      <Redirect
        to={`${next || "/create-account/"}?back=${encodeURIComponent(
          `/onboarding?step=${ONBOARDING_STEPS.length - 1}`
        )}`}
      />
    );
  }
}
