import check from "check-types";
import moment from "moment";
import objectPick from "object.pick";

export default class TerminalsSdk {
  constructor(sdk) {
    this.sdk = sdk;
  }

  nameFromId(terminalId) {
    const { cache: { locations = {} } = {} } = this.sdk.getAppState();
    if (
      !locations ||
      !locations[terminalId] ||
      !check.nonEmptyString(locations[terminalId].name)
    ) {
      return null;
    }

    return locations[terminalId].name;
  }
}
